    import * as React from 'react'
    import { useTheme, styled } from '@mui/material/styles'
    import StyledDropzone from "../utilities/DropZone";
    import GetAppIcon from '@mui/icons-material/GetApp';
    import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
    import CloseIcon from '@mui/icons-material/Close';
    import TaskIcon from '@mui/icons-material/Task';
    import InfoIcon from '@mui/icons-material/Info';
    import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
    import Pagination from '@mui/material/Pagination';
    import RestartAltIcon from '@mui/icons-material/RestartAlt';
    import {
        Grid,
        Menu,
        Typography,
        Container,
        Button,
        useMediaQuery,
        TextField,
        Modal,
        Box,
        Select,
        MenuItem,
        InputLabel,
        FormControl,
        IconButton,
        OutlinedInput,
        InputAdornment,
        Collapse,
        Table,
        TableBody,
        TableCell,
        TableContainer,
        TableHead,
        TableRow,
        Paper,
        Accordion,
        AccordionSummary,
        AccordionDetails,Fade, Backdrop,
        Dialog,
        DialogActions,
        DialogContent,
        DialogContentText,
        DialogTitle,
        Popper,
        Grow, ClickAwayListener,
        MenuList
    } from '@mui/material'
    import XLSX from  'sheetjs-style'
    import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
    import Tooltip from '@mui/material/Tooltip';
    import { Search } from '@mui/icons-material'
    import CheckCircleIcon from '@mui/icons-material/CheckCircle';
    import CancelIcon from '@mui/icons-material/Cancel';
    import DeleteIcon from '@mui/icons-material/Delete';
    import FilePresentIcon from '@mui/icons-material/FilePresent'
    import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
    import { useNavigate } from "react-router-dom"
    import { useSelector } from 'react-redux'
    import { modeContext } from '../../context/modeContext.js'
    import { gridSpacing } from '../../store/constant.js'
    import { listarParametros } from '../../services/Parametro.js'
    import PickArticle from '../shared/article/PickArticle.js' 
    import DataGridApp from '../../ui-component/grid/DataGridApp'
    import StatsCard from '../../ui-component/cards/StatsCard.js'
    import MainCard from '../../ui-component/cards/MainCard.js'
    import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
    import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
    import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
    import esLocale from 'date-fns/locale/es'
    import SearchIcon from '@mui/icons-material/Search'
    import PickSerie from '../shared/serie/PickSerie.js'
    import LinkIcon from '@mui/icons-material/Link';
    import { Alert } from '@mui/material';
    import ExpandMore from "@mui/icons-material/ExpandMore";
    import { jwtDecode } from 'jwt-decode';


    import { IconFileDownload, IconPerspective  } from '@tabler/icons';
    import { ro } from 'date-fns/locale';
    import AttachmentIcon from '@mui/icons-material/Attachment';
    import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ConsoleView } from 'react-device-detect';
    // Estilos para las tarjetas
    const CardWrapper = styled(MainCard)(({ theme }) => ({
        overflow: 'hidden',
        position: 'relative',
        backgroundColor: '#fafafa'
    }))

    const MantenimientoMaquinarias = () => {
        const theme = useTheme()
        const customization = useSelector((state) => state.customization)
        const { token, tokenCompany , user} = React.useContext(modeContext)
        const decodedToken = jwtDecode(token);
        const userProfile = decodedToken.data.profile;
        const matchDownSM = useMediaQuery(theme.breakpoints.down('md'))
        const navigate = useNavigate()
        const [openErrorModal, setOpenErrorModal] = React.useState(false);
        const [errorModalMessage, setErrorModalMessage] = React.useState('');
        const [openModal, setOpenModal] = React.useState(false)
        const [openRegistrarModal, setOpenRegistrarModal] = React.useState(false);
        const [selectedRowData, setSelectedRowData] = React.useState({
        }); 
        const [open, setOpen] = React.useState(false);
        const anchorRef = React.useRef(null);
    
        const handleToggle = () => {
            setOpen((prevOpen) => !prevOpen);
        };
    
        const handleClose = (event) => {
            if (anchorRef.current && anchorRef.current.contains(event.target)) {
                return;
            }
            setOpen(false);
        };
        const [openOrigenCostoModal, setOpenOrigenCostoModal] =React.useState(false);
        const [openModalReprogramar, setOpenModalReprogramar] =React.useState(false);
        const [selectedOrigen, setSelectedOrigen] =React.useState('');
        const [fechaReprogramacion, setFechaReprogramacion] =React.useState(null);
        const [costo, setCosto] =React.useState('');
        const [selectedTechnician, setSelectedTechnician] = React.useState('');
        const [inspectionDate, setInspectionDate] = React.useState(new Date());
        const [fechaProgramada, setFechaProgramada] = React.useState({
            fechaProgramada: null, 
        });
        const [anchorEl, setAnchorEl] = React.useState(null); 
                const [openModalAprobar, setOpenModalAprobar] = React.useState(false);
        const [openModalCancelar, setOpenModalCancelar] = React.useState(false);
        const [openModalRechazar, setOpenModalRechazar] = React.useState(false);
        const [comentarioRechazo, setComentarioRechazo] = React.useState('');
        const [selectedRow, setSelectedRow] = React.useState(null);
        const [loadingCreacionMantenimientos, setLoadingCreacionMantenimientos] = React.useState(false);
        const [tipoMantenimiento, setTipoMantenimiento] = React.useState('')
        const [tipoAlmacenNuevo, setTipoAlmacenNuevo] = React.useState('Todos'); 
        const [tipoAlmacen, setTipoAlmacen] = React.useState('')
        const [cuenta, setCuenta] = React.useState('')
        const [almacen, setAlmacen] = React.useState('')
        const [openConfirmModal, setOpenConfirmModal] = React.useState(false);

        const [articulo, setArticulo] = React.useState({
            CodArticle: '',
            IdArticle: '',
            Article: '',
            IdAlmacen:''
        })
        const [serie, setSerie] = React.useState({
            IdControlSerie: null,
            SerialNumber: '',
            Placa: ''
        });
        const [filtroEstadoMantenimiento, setFiltroEstadoMantenimiento] = React.useState('');
        const [openSerie, setOpenSerie] = React.useState(false);
        
        const [comentarios, setComentarios] = React.useState('')
        const [comentariosRegistro, setComentariosRegistro] = React.useState('')
        const [archivo, setArchivo] = React.useState([]);
        const [attachedFile, setAttachedFile] = React.useState([]);
        const [disabledCuenta, setDisabledCuenta] = React.useState(false)

        const [tipoAlmacenOptions, setTipoAlmacenOptions] = React.useState([])
        const [almacenOptions, setAlmacenOptions] = React.useState([])
        const [page, setPage] = React.useState(1);
        const [pageSize, setPageSize] = React.useState(10);
        const [totalCount, setTotalCount] = React.useState(0);
        
        const [openFind, setOpenFind] = React.useState(false)
        const [disabledArticle, setDisabledArticle] = React.useState(true)
        const [estadoMantenimiento, setEstadoMantenimiento] = React.useState('');
        const [filtroTipoMantenimiento, setFiltroTipoMantenimiento] = React.useState('');
        const [placa, setPlaca] = React.useState('');
        const [filtroReprogramado, setFiltroReprogramado] = React.useState('');
        const [cuentaOptions, setCuentaOptions] = React.useState([]);
        const [cuentaOptionsFiltro, setCuentaOptionsFiltro] = React.useState([]);
        const [dateInit, setDateInit] = React.useState(null)
        const [dateFin, setDateFin] = React.useState(null)
        const [almacenesCargados, setAlmacenesCargados] = React.useState(false);
        const [errorMessage, setErrorMessage] = React.useState(null);
        const columnsComentarios = [
            { field: 'FechaRegistro', headerName: 'Fecha Registro', width: 150 },
            { field: 'Comentarios', headerName: 'Comentario', width: 350 },
            { field: 'EstadoMantenimiento', headerName: 'Evento mantenimiento', width: 150 }
        ];
        
        const [stats, setStats] = React.useState({
            "NO_INICIADO": 0,
            "PROGRAMADO": 0,
            "EN_PROCESO": 0,
            "PENDIENTE_POR_CONFIRMAR": 0,
            "EJECUTADO": 0,
            "TODOS":0,
        })
        const handleOpenConfirmModal = () => setOpenConfirmModal(true);
        const handleCloseConfirmModal = () => setOpenConfirmModal(false);
        const [heades, setHeades] = React.useState([])
        const [openRowIndex, setOpenRowIndex] = React.useState(null)
        const [openWarningModal, setOpenWarningModal] = React.useState(false);
        const [openModalComentarios, setOpenModalComentarios] = React.useState(false);
        const [detallesComentarios, setDetallesComentarios] = React.useState([]);
        const [currentMantenimientoId, setCurrentMantenimientoId] = React.useState(null);
        const [currentPlaca, setCurrentPlaca] = React.useState(null);
        const isMobile = useMediaQuery(theme.breakpoints.down("xs")); 
        const handleOpenFindModalComentarios = async (
            openModalComentarios,
            IdDetalleMantenimiento,
            parametroNombre,
            comentarioFinalizado = null,
            estadoSolicitud = null,
            fechaCreacion = null,
            origen = null,
            costo = null,
            esMantenimiento = null
        ) => {
            try {
                console.log("DETALLEMANTENIMIENTO "+ IdDetalleMantenimiento)
                console.log("COMENTARIO FINALIZADO +" + comentarioFinalizado);
                console.log("ESTADO SOLICITUD X +" + estadoSolicitud);
                console.log("FECHA DE CREACION +" + fechaCreacion);
                if (estadoSolicitud === "Cancelado" || estadoSolicitud === "Finalizado" || estadoSolicitud === "De Baja") {
                    setDetallesComentarios([
                        {
                            FechaRegistro: fechaCreacion,
                            Comentarios: comentarioFinalizado,
                            EstadoMantenimiento: estadoSolicitud,
                            Origen: origen,
                            Costo: costo,
                        },
                    ]);
                    setOpenModalComentarios(openModalComentarios);
                    return;
                }
        
                let url = `${process.env.REACT_APP_API}business/api/mantenimiento-maquinarias/mantenimientos/detalle/${IdDetalleMantenimiento}`;
                if (esMantenimiento === 1) {
                    url = `${process.env.REACT_APP_API}business/api/mantenimiento-maquinarias/mantenimiento/${IdDetalleMantenimiento}`;
                }
        
                const response = await fetch(url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        token: token,
                        empresa: tokenCompany,
                        'Cache-Control': 'no-cache',
                        Pragma: 'no-cache',
                        Expires: '0',
                    },
                });
        
                const data = await response.json();
        
                if (Array.isArray(data)) {
                    const dataConParametro = data.map(detalle => ({
                        ...detalle,
                        FechaRegistro: formatFecha(detalle.FechaRegistro), 
                        parametroNombre, 
                    }));
                    setDetallesComentarios(dataConParametro);
                } else {    
                    setDetallesComentarios([{ ...data, parametroNombre,FechaRegistro: formatFecha(data.FechaRegistro)
                    }]);
                }
               
                setOpenModalComentarios(openModalComentarios);
            } catch (error) {
                console.error('Error al obtener los detalles:', error);
            }
        };
        

            const formatFecha = (fechaISO) => {
            const fecha = new Date(fechaISO);
            const dia = String(fecha.getDate()).padStart(2, '0');
            const mes = String(fecha.getMonth() + 1).padStart(2, '0'); 
            const año = fecha.getFullYear();
            return `${dia}/${mes}/${año}`;  
            };
        
        React.useEffect(() => {
            const fetchTipoAlmacenYTransferenciaOptions = async () => {
                try {
                    const resultTipoDeAlmacenes = await listarParametros(process.env.REACT_APP_ID_GRUPO_PARAMETRO_TIPO_ALMACENES, token, tokenCompany);
                    setTipoAlmacenOptions(resultTipoDeAlmacenes);
                } catch (error) {
                    console.error("Error al obtener los tipos de almacén:", error);
                }
            };
            fetchTipoAlmacenYTransferenciaOptions();
        }, [token, tokenCompany]);

        const handleLeerExcel = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API}business/api/mantenimiento-maquinarias/leer-excel`, {
                    method: 'GET', 
                    headers: {
                        'Content-Type': 'application/json',
                        token: token,
                        empresa: tokenCompany,
                        'Cache-Control': 'no-cache', 
                        'Pragma': 'no-cache',
                        'Expires': '0',
                    }
                });
        
                if (!response.ok) {
                    throw new Error('Error al leer el archivo Excel.');
                }
        
                const data = await response.json();
                console.log("Datos del Excel:", data);
                alert("Datos del Excel leídos exitosamente.");
            } catch (error) {
                console.error("Error al leer el archivo Excel:", error);
                alert("Error al leer el archivo Excel.");
            }
        };
        
        const handleOpenOrigenCostoModal = async (idMantenimiento) => {
            setCurrentMantenimientoId(idMantenimiento);
        
            try {
                const response = await fetch(`${process.env.REACT_APP_API}business/api/mantenimiento-maquinarias/obtener-detalles/${idMantenimiento}`,
                    {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            token: token, 
                            empresa: tokenCompany,
                            'Cache-Control': 'no-cache',
                            Pragma: 'no-cache',
                            Expires: '0',
                        },
                    }
                );
                const data = await response.json();
        
                setSelectedOrigen(data.data.Origen || '');
                setCosto(data.data.Costo || '');
                setComentarios(data.data.Comentarios || '');
        
                setOpenOrigenCostoModal(true);
            } catch (error) {
                console.error("Error al cargar los detalles del mantenimiento:", error);
            }
        };
        
        
        const handleCloseOrigenCostoModal = () => {
            setOpenOrigenCostoModal(false);
            setSelectedOrigen('');
            setCosto('');
        };
        const handleOpenModalReprogramar = (idMantenimiento,placa) => {
            setCurrentMantenimientoId(idMantenimiento);
            setCurrentPlaca(placa);
            setOpenModalReprogramar(true);
        };
        
        const handleCloseModalReprogramar = () => {
            setOpenModalReprogramar(false);
        };
        
        const handleAgregarOrigenCosto = async () => {
            console.log("Origen:", selectedOrigen);
            console.log("Costo:", costo);
            console.log("currentidmantenimiento:", currentMantenimientoId);
        
            const payload = {
                idMantenimiento: currentMantenimientoId,
                origen: selectedOrigen,
                costo: costo,
                comentarios: comentarios
            };
        
        
            try {
                const response = await fetch(
                    `${process.env.REACT_APP_API}business/api/mantenimiento-maquinarias/agregar-detalles`,
                    {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            token: token, 
                            empresa: tokenCompany,
                            'Cache-Control': 'no-cache',
                            Pragma: 'no-cache',
                            Expires: '0',
                        },
                        body: JSON.stringify(payload),
                    }
                );
        
                if (!response.ok) {
                    const errorData = await response.json();
                    console.error('Error al enviar los datos:', errorData);
                    alert(`Error: ${errorData.message || 'No se pudo completar la solicitud'}`);
                    return;
                }
        
                const responseData = await response.json();
                console.log('Respuesta del servidor:', responseData);
                alert('Datos enviados correctamente');
        
                handleCloseOrigenCostoModal();
            } catch (error) {
                console.error('Error en la solicitud:', error);
                alert('Ocurrió un error al comunicarse con el servidor');
            }
        };
        

        const handleAgregarReprogramacion = async () => {
            console.log("currentidmantenimiento:", currentMantenimientoId);
        
            const payload = {
                idMantenimiento: currentMantenimientoId,
                placa: currentPlaca,
                fecha: fechaReprogramacion,
            };
        
        
            try {
                const response = await fetch(
                    `${process.env.REACT_APP_API}business/api/mantenimiento-maquinarias/reprogramar-fechas`,
                    {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            token: token, 
                            empresa: tokenCompany,
                            'Cache-Control': 'no-cache',
                            Pragma: 'no-cache',
                            Expires: '0',
                        },
                        body: JSON.stringify(payload),
                    }
                );
        
                if (!response.ok) {
                    const errorData = await response.json();
                    console.error('Error al enviar los datos:', errorData);
                    alert(`Error: ${errorData.message || 'No se pudo completar la solicitud'}`);
                    return;
                }
        
                const responseData = await response.json();
                console.log('Respuesta del servidor:', responseData);
                alert('Reprogramación Guardada Correctamente');
        
                handleCloseModalReprogramar();
            } catch (error) {
                console.error('Error en la solicitud:', error);
                alert('Ocurrió un error al comunicarse con el servidor');
            }
        };
        

        const fetchCuentasOptions = async () => {
            try {
                let urlNegocios = `${process.env.REACT_APP_API}business/api/tipo_negocio/TipoNegocioUsuario`;
                let urlProveedores = `${process.env.REACT_APP_API}business/api/tipo_negocio/TipoProveedorUsuario`;
        
                let cuentaOptionsMerged = [];
                let cuentaOptionsMergedFiltro = [];
        
                if (tipoAlmacen === "Almacen-Cliente") {
                    const tipoNegocios = await fetch(urlNegocios, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            token: token,
                            empresa: tokenCompany,
                            'Cache-Control': 'no-cache', 
                            'Pragma': 'no-cache',
                            'Expires': '0',
                        }
                    }).then((res) => res.json());
        
                    cuentaOptionsMerged = tipoNegocios.map((item) => ({ ...item, tipo: "negocio" }));
                } else if (tipoAlmacen === "Almacen-Proveedor") {
                    const proveedores = await fetch(urlProveedores, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            token: token,
                            empresa: tokenCompany
                        }
                    }).then((res) => res.json());
        
                    cuentaOptionsMerged = proveedores.map((item) => ({ ...item, tipo: "proveedor" }));
                } else if (tipoAlmacen === "Todos") {
                    const [tipoNegocios, proveedores] = await Promise.all([
                        fetch(urlNegocios, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                token: token,
                                empresa: tokenCompany
                            }
                        }).then((res) => res.json()),
                        fetch(urlProveedores, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                token: token,
                                empresa: tokenCompany
                            }
                        }).then((res) => res.json())
                    ]);
        
                    cuentaOptionsMerged = [
                        ...tipoNegocios.map((item) => ({ ...item, tipo: "negocio" })),
                        ...proveedores.map((item) => ({ ...item, tipo: "proveedor" }))
                    ];
                }
                else if (tipoAlmacenNuevo==='Todos') {
                    const [tipoNegocios, proveedores] = await Promise.all([
                        fetch(urlNegocios, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                token: token,
                                empresa: tokenCompany
                            }
                        }).then((res) => res.json()),
                        fetch(urlProveedores, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                token: token,
                                empresa: tokenCompany
                            }
                        }).then((res) => res.json())
                    ]);
        
                    cuentaOptionsMergedFiltro = [
                        ...tipoNegocios.map((item) => ({ ...item, tipo: "negocio" })),
                        ...proveedores.map((item) => ({ ...item, tipo: "proveedor" }))
                    ];
                }
                setCuentaOptions(cuentaOptionsMerged);
                setCuentaOptionsFiltro(cuentaOptionsMergedFiltro)
            } 
            catch (error) {
                console.error("Error:", error);
            }
        };

        const handlePreviewFileDetalle = async (idDetalleMantenimiento) => {
            try {
                const response = await fetch(
                    `${process.env.REACT_APP_API}business/api/mantenimiento-maquinarias/detalle/${idDetalleMantenimiento}/archivo-adjunto`,
                    {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            token: token,
                            empresa: tokenCompany,
                            cache: 'no-cache',
                            pragma: 'no-cache',
                            'cache-control': 'no-cache'
                        },
                    }
                );
        
                if (!response.ok) {
                    throw new Error("Error al obtener los archivos asociados.");
                }
        
                const data = await response.json();
        
                if (data && data.length > 0) {
                    data.forEach((archivo) => {
                        window.open(archivo.signedUrl, "_blank");
                    });
                } else {
                    alert("No hay archivos asociados a este mantenimiento.");
                }
            } catch (error) {
                console.error("Error al previsualizar los archivos:", error);
                alert("No hay archivos asociados a este mantenimiento.");
            }
        };

        const handlePreviewFileMantenimiento = async (idMantenimiento) => {
            try {
                console.log("ID MANTENIMIENTO "+ idMantenimiento)
                const response = await fetch(
                    `${process.env.REACT_APP_API}business/api/mantenimiento-maquinarias/mantenimiento/${idMantenimiento}/archivo-adjunto`,
                    {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            token: token,
                            empresa: tokenCompany,
                            cache: 'no-cache',
                            pragma: 'no-cache',
                            'cache-control': 'no-cache'
                        },
                    }
                );
        
                if (!response.ok) {
                    throw new Error("Error al obtener los archivos asociados.");
                }
        
                const data = await response.json();
        
                if (data && data.length > 0) {
                    data.forEach((archivo) => {
                        window.open(archivo.signedUrl, "_blank");
                    });
                } else {
                    alert("No hay archivos asociados a este mantenimiento.");
                }
            } catch (error) {
                console.error("Error al previsualizar los archivos:", error);
                alert("No hay archivos asociados a este mantenimiento.");
            }
        };

        const fetchAlmacenes = async () => {
            let url = `${process.env.REACT_APP_API}business/api/almacen/getAlmacenEmpresa`;
        
            if (tipoAlmacen === "Almacen-Proveedor" || tipoAlmacen === "Almacen-Cliente") {
                const tipoCuenta = tipoAlmacen === "Almacen-Proveedor" ? 'proveedor' : 'negocio';
                url += `?tipoDeAlmacen=${tipoAlmacen}&cuentaAreaId=${cuenta}&tipoCuenta=${tipoCuenta}`;
            }
            else if (tipoAlmacen === "Almacen-Empresa") {
                url += `?tipoDeAlmacen=${tipoAlmacen}`;
            }
        
            const options = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    token: token,
                    empresa: tokenCompany,
                    cache: 'no-cache',
                    pragma: 'no-cache',
                    'cache-control': 'no-cache'
                }
            };
        
            try {
                const res = await fetch(url, options);
                const response = await res.json();
                setAlmacenOptions(response);
                setAlmacenesCargados(true);
            } catch (error) {
                console.error('Error:', error);
            }
        };
        
        React.useEffect(() => {
            setCuenta('') 
            setAlmacen('') 
            setCuentaOptions([]) 
            setAlmacenOptions([]) 

            if (tipoAlmacen === "Almacen-Empresa") {
                setDisabledCuenta(true) 
                fetchAlmacenes()
            } else {
                setDisabledCuenta(false) 
            }
        }, [tipoAlmacen])

        React.useEffect(() => {
            if (tipoAlmacen === "Almacen-Empresa" || (cuenta && (tipoAlmacen === "Almacen-Proveedor" || tipoAlmacen === "Almacen-Cliente" || tipoAlmacen === "Todos"))) {
                fetchAlmacenes()
            }
        }, [cuenta, tipoAlmacen])

        const handleSearch = () => {
            const query = {};
            
            if (filtroTipoMantenimiento) {
                query.TipoMantenimiento = filtroTipoMantenimiento;
            }
            if (filtroEstadoMantenimiento) {
                query.EstadoMantenimiento = filtroEstadoMantenimiento;
            }
            if (placa) {
                query.Placa = placa;
            }
            
            if (cuenta) {
                query.IdNegocio = cuenta;
            }
        
            if (dateInit) {
                query.FechaInicio = dateInit.toISOString().split('T')[0];
            }
            
            if (dateFin) {
                query.FechaFin = dateFin.toISOString().split('T')[0];
            }
            if (filtroReprogramado) {
                query.FiltroReprogramado = filtroReprogramado;
            }

            setPage(1);
            find(query.TipoMantenimiento,query.EstadoMantenimiento, query.Placa, query.IdNegocio, query.FechaInicio, query.FechaFin, query.FiltroReprogramado);
        };
        
        
        React.useEffect(() => {
            if (tipoAlmacen === "Almacen-Proveedor" || tipoAlmacen === "Almacen-Cliente" || tipoAlmacen === "Todos" || tipoAlmacenNuevo==='Todos') {
                fetchCuentasOptions()
            }
        }, [tipoAlmacen])
            const fetchConteoEstados = async () => {
                try {
                    const response = await fetch(`${process.env.REACT_APP_API}business/api/mantenimiento-maquinarias/conteo-estados`, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            token: token,
                            empresa: tokenCompany,
                            'Cache-Control': 'no-cache', 
                            'Pragma': 'no-cache',
                            'Expires': '0',
                        }
                    });
                    const data = await response.json();
                    
                    const estadoStats = {
                        NO_INICIADO: 0,
                        PROGRAMADO: 0,
                        EN_PROCESO: 0,
                        PENDIENTE_POR_CONFIRMAR: 0,
                        EJECUTADO: 0,
                        TODOS:0,
                    };
        
                    data.forEach((item) => {
                        switch (item.estado) {
                            case 'No Iniciado':
                                estadoStats.NO_INICIADO = item.cantidad;
                                break;
                            case 'Programado':
                                estadoStats.PROGRAMADO = item.cantidad;
                                break;
                            case 'En Proceso':
                                estadoStats.EN_PROCESO = item.cantidad;
                                break;
                            case 'Pendiente por Confirmar':
                                estadoStats.PENDIENTE_POR_CONFIRMAR = item.cantidad;
                                break;
                            case 'Finalizado':
                                estadoStats.FINALIZADO = item.cantidad;
                                break;
                            case 'Todos':
                                estadoStats.TODOS = item.cantidad;
                                break;
                            default:
                                break;
                        }
                    });
        
                    setStats(estadoStats);
                } catch (error) {
                    console.error('Error al obtener los conteos de estados:', error);
                }
            };
        
        React.useEffect(() => {
            fetchConteoEstados(); 
        }, [token, tokenCompany]);
        
        const handleOpenFind = () => {
            if (almacen) {
                setOpenFind(true);
            }
        }
        const handleSelectedArticle = (article) => {
            console.log("ARTICULO + " + JSON.stringify(article));
            if (article.Stock <= -0) {
                setOpenWarningModal(true);
            } else {
                setArticulo({
                    IdAlmacen: article.IdAlmacen,
                    CodArticle: article.CodArticulo,
                    IdArticle: article.IdArticulo,
                    Article: article.Articulo,
                    Stock: article.Stock,
                    Grupo:article.Grupo,
                    U_Devolucion:article.U_Devolucion,
                    IdNegocioArticulo:article.IdNegocio
                });
            }
            setOpenFind(false); 
        };

        const columnsPickSerie = [
            { field: 'Placa', headerName: 'Placa', width: 400 },
        ];

        const handleConfirm = () => {
            setOpenConfirmModal(false); 
            crearMantenimientoAlertas(); 
        };

        const crearMantenimientoAlertas = async () => {
            setLoadingCreacionMantenimientos(true); 
            try {
                const response = await fetch(`${process.env.REACT_APP_API}business/api/mantenimiento-maquinarias/crear-mantenimiento-alertas`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        token: token,
                        empresa: tokenCompany,
                        'Cache-Control': 'no-cache',
                        Pragma: 'no-cache',
                        Expires: '0',
                    },
                });
    
                if (response.ok) {
                    const data = await response.json();
                    console.log('Mantenimiento creado exitosamente:', data);
                    find();
                    alert('Mantenimientos creados exitosamente');
                } else {
                    const errorData = await response.json();
                    console.error('Error al crear mantenimientos:', errorData);
                    alert(`Error: ${errorData.error || 'No se pudo crear mantenimientos'}`);
                }
            } catch (error) {
                console.error('Error de conexión:', error);
                alert('Error de conexión con el servidor.');
            }
            finally {
                setLoadingCreacionMantenimientos(false); 
            }
        };

        const handleGuardar = async () => {
            if (!articulo.IdArticle || !almacen) {
                setErrorMessage("Todos los campos son obligatorios.");
                return;
            }
        
            const mantenimientoData = {
                TipoAlmacen: tipoAlmacen,
                IdNegocio: articulo.IdNegocioArticulo,
                IdAlmacen: almacen,
                IdArticulo: articulo.IdArticle,
                Descripcion: articulo.Article,
                Comentarios: comentarios,
                FechaCreacion: new Date().toISOString(),
                TipoMantenimiento: tipoMantenimiento,
                Placa: serie.Placa,
                Stock: articulo.Stock,
                ItemCode: articulo.CodArticle,
                ItemName: articulo.Article,
                Cantidad: 1,
                U_Devolucion: articulo.U_Devolucion,
                Grupo: articulo.Grupo,
                Serie: serie.SerialNumber,
                IdControlSerie: serie.IdControlSerie,
            };
            console.log("mantenimientoData", mantenimientoData);

            try {
                const formData = new FormData();
                Object.keys(mantenimientoData).forEach((key) => {
                    console.log(`Appending key: ${key}, value: ${mantenimientoData[key]}`);
                    formData.append(key, mantenimientoData[key]);
                });
                
        
                if (archivo && archivo.length > 0) {
                    archivo.forEach((file) => {
                        formData.append("attached", file);
                    });
                }
        
                const response = await fetch(`${process.env.REACT_APP_API}business/api/mantenimiento-maquinarias/`, {
                    method: "POST",
                    headers: {
                        token: token,
                        empresa: tokenCompany,
                    },
                    body: formData,
                });
        
                if (!response.ok) {
                    const errorData = await response.json();
                    throw new Error(errorData.message || "Error al crear el mantenimiento.");
                }
        
                const data = await response.json();
                console.log("Mantenimiento creado:", data);
        
                setOpenModal(false);
                find();
                setTipoAlmacen('');
                setCuenta('');
                setAlmacen('');
                setArticulo({
                    CodArticle: '',
                    IdArticle: '',
                    Article: '',
                    IdAlmacen: ''
                });
                setComentarios('');
                setTipoMantenimiento('');
                setSerie({
                    IdControlSerie: null,
                    SerialNumber: '',
                    Placa: ''
                });
                setAttachedFile([]);
                fetchConteoEstados();
            } catch (error) {
                console.error("Error al crear el mantenimiento:", error);
                setErrorMessage(error.message);
            }
        };
        
        
        const handleSelectedSerie = (selectedSerie) => {
            setSerie({
                IdControlSerie: selectedSerie.id,
                SerialNumber: selectedSerie.SerialNumber,
                Placa: selectedSerie.Placa
            });
        };

       
        
        const find = React.useCallback((tipoMantenimiento,estadoMantenimiento, placa, cuenta, fechaInicio, fechaFin, filtroReprogramado, page = 1, pageSize = 10) => {
            const query = new URLSearchParams();
        
            if (tipoMantenimiento) {
                query.append('TipoMantenimiento', tipoMantenimiento);
            }
            if (estadoMantenimiento) {
                query.append('EstadoMantenimiento', estadoMantenimiento);
            }
            if (placa) {
                query.append('Placa', placa);
            }
        
            if (cuenta) {
                query.append('IdNegocio', cuenta);
            }
        
            if (fechaInicio) {
                query.append('FechaInicio', fechaInicio);
            }
        
            if (fechaFin) {
                query.append('FechaFin', fechaFin);
            }
        
            if (filtroReprogramado) {
                query.append('FiltroReprogramado', filtroReprogramado);
            }
        
            query.append('page', page);
            query.append('pageSize', pageSize);
        
            const url = `${process.env.REACT_APP_API}business/api/mantenimiento-maquinarias?${query.toString()}`;
        
            fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    token: token,
                    empresa: tokenCompany,
                    'Cache-Control': 'no-cache', 
                    'Pragma': 'no-cache',
                    'Expires': '0',
                }
            })
            .then(res => res.json())
            .then(response => {
                setHeades(response.data);
                setTotalCount(response.totalCount); 
            })
            .catch(error => console.error('Error:', error));
        }, [token, tokenCompany]);

        React.useEffect(() => {
            find();
        }, [find]);

        const handlePageSizeChange = (event) => {
            const newPageSize = event.target.value;
            setPageSize(newPageSize);
            setPage(1);
            find(filtroTipoMantenimiento,filtroEstadoMantenimiento, placa, cuenta, dateInit, dateFin, filtroReprogramado, 1, newPageSize);
        };
    
        const handlePageChange = (event, value) => {
            setPage(value);
            find(filtroTipoMantenimiento, filtroEstadoMantenimiento, placa, cuenta, dateInit, dateFin, filtroReprogramado, value, pageSize);
        };
        
        const startIndex = (page - 1) * pageSize + 1;
        const endIndex = Math.min(page * pageSize, totalCount);

        
        const columns = [
            { field: 'correlativo', headerName: 'Número Solicitud', flex: 0.3, maxWidth: 100, headerAlign: 'center', align: 'center' },
            { field: 'tipoMantenimiento', headerName: 'Tipo Mant.', flex: 0.3, maxWidth: 100, headerAlign: 'center', align: 'center' },
            { field: 'usuarioCreacion', headerName: 'Supervisor', flex: 0.3, maxWidth: 100, headerAlign: 'center', align: 'center' },
            {
                field: 'negocio',
                headerName: 'Cliente',
                flex: 0.3,
                headerAlign: 'center',
                align: 'center',
                renderCell: (params) => (
                    <Tooltip title={params.row.negocio || ''} arrow>
                        <Typography
                            variant="body2"
                            align="center"
                            sx={{
                                fontSize: '12px',  
                                padding: '4px',   
                                whiteSpace: 'nowrap', 
                                overflow: 'hidden',   
                                textOverflow: 'ellipsis',
                                maxWidth: '80px',  
                            }}
                        >
                            {params.row.negocio}
                        </Typography>
                    </Tooltip>
                ),
            }
,            
            { field: 'almacen', headerName: 'Almacén', flex: 0.3, maxWidth: 120, headerAlign: 'center', align: 'center' },
            {
                field: 'articulo',
                headerName: 'Maquina',
                flex: 0.3,
                headerAlign: 'center',
                align: 'center',
                renderCell: (params) => (
                    <Tooltip title={params.row.articulo || ''} arrow>
                        <Typography
                            variant="body2"
                            align="center"
                            sx={{
                                fontSize: '12px',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                maxWidth: '140px',
                            }}
                        >
                            {params.row.articulo}
                        </Typography>
                    </Tooltip>
                ),
            }
,           { field: 'placa', headerName: 'Placa', flex: 0.3, maxWidth: 100, headerAlign: 'center', align: 'center' },
            { field: 'fechaSolicitud', headerName: 'Fecha Solicitud', flex: 0.3, maxWidth: 120, headerAlign: 'center', align: 'center' },
            {
                field: 'estadoSolicitud',
                headerName: 'Estado Solicitud',
                flex: 0.3,
                headerAlign: 'center',
                align: 'center',
                renderCell: (params) => (
                    <Typography
                        variant="body2"
                        align="center"
                        sx={{
                            fontSize: '12px',  
                            padding: '4px',   
                            whiteSpace: 'nowrap', 
                            overflow: 'hidden',   
                            textOverflow: 'ellipsis',
                            maxWidth: '80px',  
                        }}
                    >
                        {params.row.estadoSolicitud}
                    </Typography>
                ),
            },
            
            {
                field: 'registrarInspeccion',
                headerName: 'Acciones',
                flex: 0.3,
                headerAlign: 'center',
                align: 'center',
                renderCell: (params) => {
                   
                    console.log (userProfile)
                    const hasAdminRole = userProfile?.some(profile => profile.name === 'Administrador de maquinas');
                
                const handleAction = (action) => {
                    if (!hasAdminRole) {
                        setErrorMessage('Solo los Usuarios que tienen el rol de Administrador de Maquinarias pueden aprobar o rechazar mantenimientos');
                        return;
                    }

                    if (action === 'aprobar') {
                        setSelectedRow(params.row);
                        setOpenModalAprobar(true);
                    } else if (action === 'rechazar') {
                        setSelectedRow(params.row);
                        setOpenModalRechazar(true);
                    }
                };
                    const { estadoSolicitud, comentarioFinalizado ,fechaSolicitud } = params.row; 
                    if(estadoSolicitud==='De Baja'||estadoSolicitud === 'Cancelado' || estadoSolicitud === 'Finalizado'){
                        return(
                            <IconButton
                                color="primary"
                                aria-label="Detalle"
                                onClick={() => handleOpenFindModalComentarios(true, params.row.id,null, comentarioFinalizado,estadoSolicitud,fechaSolicitud,params.row.origen,params.row.costo)} 
                            >
                                <LinkIcon />
                            </IconButton>
                        );
                    }
                    
                    if (estadoSolicitud === 'Pendiente Por Confirmar') {
                        return (
                            <Box display="flex" justifyContent="center" gap={1}>
                            <Tooltip title="Aprobar">
                                <IconButton
                                    onClick={() => handleAction('aprobar')}
                                    sx={{
                                        backgroundColor: 'green',
                                        color: 'white',
                                        '&:hover': { backgroundColor: 'darkgreen' },
                                        padding: '2px',
                                        borderRadius: '50%',
                                    }}
                                >
                                    <CheckCircleIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
        
                            <Tooltip title="Dar de Baja">
                                <IconButton
                                    onClick={() => handleAction('rechazar')}
                                    sx={{
                                        backgroundColor: 'red',
                                        color: 'white',
                                        '&:hover': { backgroundColor: 'darkred' },
                                        padding: '2px',
                                        borderRadius: '50%',
                                    }}
                                >
                                    <CancelIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        </Box>
                        );
                    }
                    
                    
                     else {
                        const solicitudPendiente = params.row.DetalleMantenimientoMaquinaria.some(
                            (detalle) => detalle.SolicitudTransferencium?.Parametro?.Nombre === 'Pendiente Programar' ||
                                         detalle.SolicitudTransferencium?.Parametro?.Nombre === 'En Proceso'||
                                         detalle.SolicitudTransferencium?.Parametro?.Nombre === 'Pendiente Aprobacion'

                        );
                        return (
                            <Box
                                display="grid"
                                gridTemplateColumns="repeat(4, 1fr)" 
                                gap={0.2}
                                sx={{
                                    width: '100%',
                                    maxWidth: '180px',
                                }}
                            >
                                <Tooltip title="Crear evento">
                                    <IconButton
                                        size="small" 
                                        onClick={() => {
                                            if (solicitudPendiente) {
                                                setErrorModalMessage('La máquina se encuentra en proceso para el evento "Transferencia"');
                                                setOpenErrorModal(true);
                                            } else {
                                                handleOpenModalRegistrar(params.row);
                                            }
                                        }}
                                        sx={{
                                            padding: '4px',
                                            backgroundColor: '#1976D2',
                                            color: 'white',
                                            '&:hover': {
                                                backgroundColor: '#115293',
                                            },
                                        }}
                                    >
                                        <TaskIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                        
                                <Tooltip title="Cancelar mantenimiento">
                                    <IconButton
                                        size="small"
                                        onClick={() => {
                                            if (solicitudPendiente) {
                                                setErrorModalMessage('La máquina se encuentra en proceso para el evento "Transferencia"');
                                                setOpenErrorModal(true);
                                            } else {
                                                setSelectedRow(params.row);
                                                setOpenModalCancelar(true);
                                            }
                                        }}
                                        sx={{
                                            padding: '4px',
                                            backgroundColor: '#D32F2F',
                                            color: 'white',
                                            '&:hover': {
                                                backgroundColor: '#9A0007',
                                            },
                                        }}
                                    >
                                        <CancelPresentationIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                        
                                <Tooltip title="Detalles">
                                    <IconButton
                                        size="small"
                                        onClick={(event) => handleMenuOpen(event, params.row)}
                                        sx={{
                                            padding: '4px',
                                            backgroundColor: '#B0B0B0',
                                            color: 'white',
                                            '&:hover': {
                                                backgroundColor: '#A0A0A0',
                                            },
                                        }}
                                    >
                                        <InfoIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                                <Menu
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleMenuClose}
                                PaperProps={{ style: { width: '200px' } }}
                            >
                                <MenuItem
                                    onClick={() => {
                                        handleOpenOrigenCostoModal(selectedRow.id);
                                        handleMenuClose(); 
                                    }}
                                >
                                    Añadir Detalles
                                </MenuItem>
                                <MenuItem
                                    onClick={() => {
                                        handleOpenFindModalComentarios(
                                            true,
                                            selectedRow.id,
                                            null,
                                            selectedRow.comentarioFinalizado,
                                            selectedRow.estadoSolicitud,
                                            selectedRow.fechaSolicitud,
                                            selectedRow.origen,
                                            selectedRow.costo,
                                            1
                                        );
                                        handleMenuClose();
                                    }}
                                >
                                    Ver Detalles
                                </MenuItem>
                                <MenuItem
                                    onClick={() => {
                                        handlePreviewFileMantenimiento(selectedRow.id);
                                        handleMenuClose();
                                    }}
                                >
                                    Descargar Doc. Adjuntos
                                </MenuItem>
                            </Menu>


                                <Tooltip title="Reprogramar">
                                    <IconButton
                                        size="small"
                                        onClick={() => handleOpenModalReprogramar(params.row.id, params.row.placa)}
                                        sx={{
                                            padding: '4px',
                                            backgroundColor: params.row.reprogramado === 1 ? '#FFA000' : '#4CAF50',
                                            color: 'white',
                                            '&:hover': {
                                                backgroundColor: params.row.reprogramado === 1 ? '#FF8F00' : '#388E3C',
                                            },
                                        }}
                                    >
                                        <CalendarMonthIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        );
                    }
                }
            },
            {
                field: 'expand',
                headerName: '',
                minWidth: 50,
                headerAlign: 'center',
                align: 'center',
                renderCell: (params) => (
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => {
                            setOpenRowIndex(openRowIndex === params.row.id ? null : params.row.id);
                        }}
                    >
                        {openRowIndex === params.row.id ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </IconButton>
                ),
            },
        ];
        const handleMenuOpen = (event, row) => {
            setAnchorEl(event.currentTarget);
            setSelectedRow(row); 
        };
        
        const handleMenuClose = () => {
            setAnchorEl(null);
            setSelectedRow(null);
        };
        
        const handleEstadoMantenimiento = async (nuevoEstado, row, comentarioFinalizado = '') => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API}business/api/mantenimiento-maquinarias/mantenimiento/${row.id}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        token: token,
                        empresa: tokenCompany
                    }
                });
        
                const mantenimiento = await response.json();
        
                console.log("Response completo:", response);
                console.log("Datos del mantenimiento:", mantenimiento);
                
                if (!response.ok) {
                    throw new Error("Error al obtener el mantenimiento");
                }
        
                const body = {
                    IdMantenimiento: row.id,
                    nuevoEstado,
                    ItemName: mantenimiento.Articulo?.ItemName,
                    ItemCode: mantenimiento.Articulo?.ItemCode,
                    Placa: mantenimiento.Placa,
                    IdAlmacen: mantenimiento.IdAlmacen,
                    IdNegocio: mantenimiento.IdNegocio,
                    IdArticulo: mantenimiento.IdArticulo,
                    IdControlSerie: mantenimiento.IdControlSerie, 
                    Grupo: mantenimiento.Grupo, 
                    Serie: mantenimiento.Serie 
                };
        
                if (nuevoEstado === 'Rechazar' || nuevoEstado === 'Cancelar' || nuevoEstado === 'Aprobar') {
                    body.comentarioFinalizado = comentarioFinalizado;
                }
        
                const updateResponse = await fetch(`${process.env.REACT_APP_API}business/api/mantenimiento-maquinarias/modificar-estado`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        token: token,
                        empresa: tokenCompany,
                    },
                    body: JSON.stringify(body)
                });
        
                const updateResult = await updateResponse.json();
                fetchConteoEstados();
                if (!updateResponse.ok) {
                    throw new Error(updateResult.message || 'Error al actualizar el estado');
                }
        
                console.log('Estado actualizado:', updateResult);
                find();
            } catch (error) {
                console.error('Error al actualizar el estado:', error);
            }
        };
        
        

        const handleOpenModalRegistrar = (row) => {
            console.log("SELECTEDDDDD"+ JSON.stringify(row))
            const ultimoTecnicoAsignado = row.DetalleMantenimientoMaquinaria?.length > 0 
        ? row.DetalleMantenimientoMaquinaria[row.DetalleMantenimientoMaquinaria.length - 1].Tecnico 
        : ''; 
        setSelectedTechnician(ultimoTecnicoAsignado || '');
            setSelectedRowData(row);
            setOpenRegistrarModal(true);
        };
        const handleEliminarDetalle = async (IdDetalleMantenimiento) => {
            if (window.confirm("¿Estás seguro de que deseas eliminar este detalle?")) {
                try {
                    const response = await fetch(`${process.env.REACT_APP_API}business/api/mantenimiento-maquinarias/detalle/${IdDetalleMantenimiento}`, {
                        method: 'DELETE',
                        headers: {
                            'Content-Type': 'application/json',
                            token: token,
                            empresa: tokenCompany,
                        }
                    });
        
                    if (response.ok) {
                        find(); 
                    } else {
                        console.error('Error al eliminar el detalle');
                    }
                } catch (error) {
                    console.error('Error al eliminar el detalle:', error);
                }}}
                const handleCloseModalRegistrar = () => {
                    setSelectedTechnician('');
                    setEstadoMantenimiento('');
                    setComentariosRegistro('');
                    setAttachedFile(null);
                    setOpenRegistrarModal(false);
                    setFechaProgramada(null); 
                };

        const handleCloseModal = () => {
            resetFields();
            setOpenModal(false);
        };
        const handleTechnicianChange = (event) => {
            setSelectedTechnician(event.target.value);
        };

        const resetFields = () => {
            setTipoMantenimiento('');
            setTipoAlmacen('');
            setCuenta('');
            setAlmacen('');
            setArticulo({
                CodArticle: '',
                IdArticle: '',
                Article: '',
                IdAlmacen: ''
            });
            setComentarios('');
            setSerie({
                IdControlSerie: null,
                SerialNumber: '',
                Placa: ''
            });
            setArchivo(null);
        };
        
        const handleSaveRegistrar = async () => {
            try {
                let errorMessages = [];
        
                if (!selectedTechnician) {
                    errorMessages.push("El técnico es obligatorio.");
                }
        
                if (!estadoMantenimiento) {
                    errorMessages.push("El estado de mantenimiento es obligatorio.");
                }
        
                if (estadoMantenimiento === "En Mantenimiento" && (!attachedFile || attachedFile.length === 0)) {
                    errorMessages.push("Para registrar 'En Mantenimiento' es obligatorio adjuntar la ficha de ejecución y fotos adicionales.");
                }
        
                if (errorMessages.length > 0) {
                    setErrorMessage(errorMessages.join("\n")); 
                    return;
                }
        
                console.log("selectedrowdata2", JSON.stringify(selectedRowData));
        
                const detalleData = {
                    IdMantenimiento: selectedRowData.id,
                    FechaRegistro: inspectionDate.toISOString(),
                    NumeroSolicitud: selectedRowData.correlativo,
                    TipoMantenimiento: selectedRowData.tipoMantenimiento,
                    Tecnico: selectedTechnician,
                    AlmacenOrigen: selectedRowData.almacen,
                    AlmacenDestino: almacen,
                    EstadoMantenimiento: estadoMantenimiento,
                    ComentariosRegistro: comentariosRegistro,
                    TipoIncidencia: selectedRowData.TipoIncidencia,
                    FechaProgramada: fechaProgramada && fechaProgramada.fechaProgramada
                        ? new Date(fechaProgramada.fechaProgramada).toISOString()
                        : null
                };
        
                const formData = new FormData();
                Object.keys(detalleData).forEach((key) => {
                    formData.append(key, detalleData[key]);
                });
        
                if (attachedFile && attachedFile.length > 0) {
                    attachedFile.forEach((file) => {
                        formData.append("attached", file);
                    });
                }
        
                const saveResponse = await fetch(
                    `${process.env.REACT_APP_API}business/api/mantenimiento-maquinarias/detalle`,
                    {
                        method: "POST",
                        headers: {
                            token: token,
                            empresa: tokenCompany,
                        },
                        body: formData,
                    }
                );
        
                const saveResult = await saveResponse.json();
                if (!saveResponse.ok) {
                    throw new Error(saveResult.message || "Error al guardar el detalle.");
                }
        
                console.log("Detalle guardado:", saveResult);
        
                setErrorMessage(null);
                handleCloseModalRegistrar();
                find();
                setSelectedTechnician("");
                setEstadoMantenimiento("");
                setComentariosRegistro("");
                setAttachedFile([]);
                fetchConteoEstados();
            } catch (error) {
                console.error("Error al guardar el detalle:", error);
                setErrorMessage(error.message || "Error al guardar el detalle.");
            }
        };
        
        
        const handleCloseWarningModal = () => {
            setOpenWarningModal(false); 
        };
        const rows = heades.map((head, index) => {
            const formattedCorrelativo = `SOL-MANT-${String(head.Correlativo).padStart(6, '0')}`;
            const formattedFechaSolicitud = head.FechaCreacion 
            ? new Date(head.FechaCreacion).toLocaleDateString('es-ES', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
            }) 
            : 'Fecha no disponible';
            return {
                id: head.id,
                correlativo: formattedCorrelativo, 
                negocio: head.TipoNegocio ? head.TipoNegocio.Nombre : '', 
                almacen: head.Almacene.Nombre,
                articulo: head.Articulo.ItemName,
                fechaSolicitud: formattedFechaSolicitud, 
                usuarioCreacion: head.Usuario? head.Usuario.Nombres : '', 
                TipoIncidencia:head.TipoIncidencia,
                origen:head.Origen,
                costo:head.Costo,
                reprogramado:head.Reprogramado,
                fechaReprogramado:head.FechaReprogramacion,
                descripcion: head.Descripcion,
                comentarios: head.Comentarios,
                tipoAlmacen: head.TipoAlmacen,
                tipoMantenimiento: head.TipoMantenimiento,
                placa: head.Placa,
                comentarioFinalizado: head.ComentarioFinalizado,
                estadoSolicitud: head.EstadoMantenimiento === 'Rechazado' ? 'De Baja' : head.EstadoMantenimiento, 
                DetalleMantenimientoMaquinaria: head.DetalleMantenimientoMaquinaria || [],
                tipoDeAlmacenCurrentSeries: head.CurrentSery?.TipoDeAlmacen ?? head.TipoAlmacen 
            };
        });
        

        
        const ExpandedRow = ({ row }) => (
            <TableRow>
                <TableCell colSpan={columns.length} sx={{ paddingBottom: 0, paddingTop: 0 }}>
                    <Collapse in={openRowIndex === row.id} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 2 }}>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center" sx={{ backgroundColor: '#f0f4f8' }}>Fecha Registro</TableCell>
                                        <TableCell align="center" sx={{ backgroundColor: '#f0f4f8' }}>Tipo de Mantenimiento</TableCell>
                                        <TableCell align="center" sx={{ backgroundColor: '#f0f4f8' }}>Tipo de Incidencia</TableCell>
                                        <TableCell align="center" sx={{ backgroundColor: '#f0f4f8' }}>Técnico</TableCell>
                                        <TableCell align="center" sx={{ backgroundColor: '#f0f4f8' }}>Almacén Origen</TableCell>
                                        <TableCell align="center" sx={{ backgroundColor: '#f0f4f8' }}>Almacén Destino</TableCell>
                                        <TableCell align="center" sx={{ backgroundColor: '#f0f4f8' }}>Evento</TableCell>
                                        <TableCell align="center" sx={{ backgroundColor: '#f0f4f8' }}>Número Solicitud</TableCell>
                                        <TableCell align="center" sx={{ backgroundColor: '#f0f4f8' , width: '180px'}}>Acciones</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {Array.isArray(row.DetalleMantenimientoMaquinaria) && row.DetalleMantenimientoMaquinaria.length > 0 ? (
                                        row.DetalleMantenimientoMaquinaria.map((detalle, index) => (
                                            <TableRow key={index}>
                                                <TableCell align="center" sx={{ backgroundColor: '#f0f4f8' }}>
                                                {new Date(detalle.FechaRegistro).toLocaleDateString('es-ES')}
                                            </TableCell>
                                            <TableCell align="center" sx={{ backgroundColor: '#f0f4f8' }}>{detalle.TipoMantenimiento}</TableCell>
                                            <TableCell align="center" sx={{ backgroundColor: '#f0f4f8' }}>
                                                {detalle.TipoIncidencia === 'null' ? '' : detalle.TipoIncidencia}
                                                </TableCell>
                                            <TableCell align="center" sx={{ backgroundColor: '#f0f4f8' }}>{detalle.Tecnico}</TableCell>
                                                <TableCell align="center" sx={{ backgroundColor: '#f0f4f8' }}>{detalle.AlmacenOrigen}</TableCell>
                                                <TableCell align="center" sx={{ backgroundColor: '#f0f4f8' }}>
                                                {detalle.AlmacenDestinoData ? detalle.AlmacenDestinoData.Nombre : (detalle.AlmacenDestino || '-')}
                                                </TableCell>
                                                <TableCell align="center" sx={{ backgroundColor: '#f0f4f8' }}>
                                                {detalle.EstadoMantenimiento}
                                                {detalle.EstadoMantenimiento === 'Transferencia' && detalle.SolicitudTransferencium?.Parametro?.Nombre && (
                                                    <Box sx={{ color: 'green', mt: 1 }}>
                                                        {detalle.SolicitudTransferencium.Parametro.Nombre}
                                                    </Box>
                                                )}
                                            </TableCell>
                                            <TableCell align="center" sx={{ backgroundColor: '#f0f4f8' }}>
                                        {detalle.SolicitudTransferencium ? (
                                            `SOLTRAN-${detalle.SolicitudTransferencium.Periodo}-${String(detalle.SolicitudTransferencium.Correlativo).padStart(6, '0')}`
                                        ) : (
                                            ''  
                                        )}
                                        </TableCell>
                                            <TableCell align="center" sx={{ backgroundColor: '#f0f4f8' }}>
                                                {detalle.EstadoMantenimiento === 'Transferencia' && !detalle.SolicitudTransferencium?.Parametro?.Nombre && (
                                        <Tooltip title="Realizar Transferencia">
                                            <span>
                                                    <IconButton
                                                    color="primary"
                                                    disabled={row.estadoSolicitud === 'Cancelado' || row.estadoSolicitud === 'De Baja' || row.estadoSolicitud === 'Finalizado'} 
                                                    onClick={() => {
                                                        navigate('/request/transferenciaAlmacen', {
                                                        state: {
                                                            tipoTransferenciaMantenimiento: 'Mantenimiento',  
                                                            idTipoTransferenciaMantenimiento: 'a64a0913-6bc9-11ef-ba28-12c11c8bfd0f', 
                                                           tipoAlmacenOrigen: row.tipoDeAlmacenCurrentSeries,
                                                           cuentaAreaOrigen: detalle.TipoNegocio?.IdNegocio, 
                                                           // cuentaAreaOrigen: 'bccb4904-e166-4da9-b8e9-b38c73818c6e', 
                                                           cuentaAreaOrigenArticulo: detalle.TipoNegocio?.IdNegocio,
                                                         //   cuentaAreaOrigenArticulo: detalle.Articulo?.GrupoArticulo?.IdNegocio,
                                                            nombreCuentaAreaOrigen: detalle.TipoNegocio?.Nombre,
                                                            almacenOrigen: detalle.IdAlmacen,
                                                            nombreAlmacenOrigen: detalle.AlmacenOrigen, 
                                                            articuloOrigen: detalle.Articulo?.IdArticulo, 
                                                            nombreArticulo: detalle.Articulo?.ItemName ,
                                                            codigoArticulo: detalle.Articulo?.ItemCode,
                                                            idDetalleMantenimiento: detalle.IdDetalleMantenimiento  
                                                        }
                                                        });
                                                    }}
                                                    size="small"
                                                    >
                                                <ArrowForwardIcon />
                                            </IconButton>
                                            </span>
                                        </Tooltip>
                                    )}

                                <Tooltip title="Descargar Documentos">
                                                        <IconButton
                                                            color="primary"
                                                            onClick={() => handlePreviewFileDetalle(detalle.IdDetalleMantenimiento)}
                                                        >
                                                            <GetAppIcon  />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <IconButton
                                                        color="primary"
                                                        aria-label="Detalle"
                                                        onClick={() => handleOpenFindModalComentarios(true, detalle.IdDetalleMantenimiento,detalle.SolicitudTransferencium?.Parametro?.Nombre,null,null)} 
                                                    >
                                                        <LinkIcon />
                                                    </IconButton>
                                                    <Tooltip title="Eliminar">
                                                    <span>
                                                        <IconButton
                                                            color="error"
                                                            onClick={() => {
                                                                if (
                                                                    detalle.SolicitudTransferencium?.Parametro?.Nombre === 'Pendiente Aprobacion' ||
                                                                    detalle.SolicitudTransferencium?.Parametro?.Nombre === 'En Proceso' ||
                                                                    detalle.SolicitudTransferencium?.Parametro?.Nombre === 'Pendiente Programar'
                                                                ) {
                                                                    setOpenErrorModal(true);
                                                                } else {
                                                                    handleEliminarDetalle(detalle.IdDetalleMantenimiento);
                                                                }
                                                            }}
                                                            size="small"
                                                            disabled={row.estadoSolicitud === 'Cancelado' || row.estadoSolicitud === 'De Baja' || row.estadoSolicitud === 'Finalizado'}
                                                        >
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </span>
                                                </Tooltip>
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    ) : (
                                        <TableRow>
                                            <TableCell align="center" colSpan={7} sx={{ backgroundColor: '#fafafa' }}>
                                                No hay detalles disponibles
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        );

        const handleDrop = (acceptedFiles) => {
            if (acceptedFiles.length > 0) {
                setAttachedFile((prevFiles) => (Array.isArray(prevFiles) ? [...prevFiles, ...acceptedFiles] : [...acceptedFiles]));
            }
        };
        const handleDropMantenimiento = (acceptedFiles) => {
            if (acceptedFiles.length > 0) {
                setArchivo((prevFiles) => (Array.isArray(prevFiles) ? [...prevFiles, ...acceptedFiles] : [...acceptedFiles]));
            }
        };
        

        const fetchAllMantenimientos = async (estadoMantenimiento, placa, cuenta, fechaInicio, fechaFin, filtroReprogramado) => {
            const query = new URLSearchParams();
        
            if (estadoMantenimiento && estadoMantenimiento !== 'Todos') {
                query.append('EstadoMantenimiento', estadoMantenimiento);
            }
        
            if (placa) {
                query.append('Placa', placa);
            }
        
            if (cuenta) {
                query.append('IdNegocio', cuenta);
            }
        
            if (fechaInicio) {
                query.append('FechaInicio', fechaInicio);
            }
        
            if (fechaFin) {
                query.append('FechaFin', fechaFin);
            }
        
            if (filtroReprogramado) {
                query.append('FiltroReprogramado', filtroReprogramado);
            }
        
            query.append('page', 1);
            query.append('pageSize', 99999);
        

            const url = `${process.env.REACT_APP_API}business/api/mantenimiento-maquinarias?${query.toString()}`;
        
            try {
                const res = await fetch(url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        token: token,
                        empresa: tokenCompany,
                        'Cache-Control': 'no-cache',
                        'Pragma': 'no-cache',
                        'Expires': '0',
                    },
                });
                
                const response = await res.json();
                return response.data; 
            } catch (error) {
                console.error('❌ Error al obtener mantenimientos:', error);
                return [];
            }
        };

        
        const exportToExcelConDetalleMantenimiento = async () => {
            const allMantenimientos = await fetchAllMantenimientos(
                estadoMantenimiento, placa, cuenta, dateInit, dateFin, filtroReprogramado
            );        

            if (allMantenimientos.length === 0) {
                console.warn("⚠ No hay datos para exportar.");
                return;
            }
        
            const combinedData = [];
        
            allMantenimientos.forEach((head) => {
                
            let fechaSolicitud = null;
            let fechaCierre = null;
        
            if (head.TipoMantenimiento === "Correctivo") {
                fechaSolicitud = head.Auditoria?.FechaSolicitudCorrectivo ? new Date(head.Auditoria.FechaSolicitudCorrectivo) : null;
                fechaCierre = head.Auditoria?.FechaFinCorrectivo ? new Date(head.Auditoria.FechaFinCorrectivo) : null;
            } else if (head.TipoMantenimiento === "Preventivo") {
                fechaSolicitud = head.Auditoria?.FechaProgramadaPreventivo ? new Date(head.Auditoria.FechaProgramadaPreventivo) : null;
                fechaCierre = head.Auditoria?.FechaFinPreventivo ? new Date(head.Auditoria.FechaFinPreventivo) : null;
            } else if (head.TipoMantenimiento === "Predictivo") {
                fechaSolicitud = head.Auditoria?.FechaProgramadaPredictivo ? new Date(head.Auditoria.FechaProgramadaPredictivo) : null;
                fechaCierre = head.Auditoria?.FechaFinPredictivo ? new Date(head.Auditoria.FechaFinPredictivo) : null;
            }
                if (head.DetalleMantenimientoMaquinaria.length > 0) {
                    head.DetalleMantenimientoMaquinaria.forEach((detalle) => {
                        combinedData.push({
                            "Número Solicitud": `SOL-MANT-${String(head.Correlativo).padStart(6, '0')}`,
                            "Supervisor": head.Usuario?.Nombres || 'N/A',
                            "Código Cliente": head.TipoNegocio?.CodigoExterno || 'N/A',
                            "Cliente": head.TipoNegocio?.Nombre || 'N/A',
                            "Sede": head.Almacene?.CodigoExterno || 'N/A',
                            "Almacén": head.Almacene?.Nombre || 'N/A',
                            "Artículo": head.Articulo?.ItemCode || 'N/A',
                            "Nombre": head.Articulo?.ItemName || 'N/A',
                            "Placa": head.Placa || 'N/A',
                            "Fecha Solicitud": head.FechaCreacion ? new Date(head.FechaCreacion) : null,
                            "Fecha Cierre": fechaCierre,
                            "Estado Solicitud": head.EstadoMantenimiento || 'N/A',
                            "Tipo Incidencia": head.TipoIncidencia || 'N/A',
                            "Costo": head.Costo || 'N/A',
                            "Origen": head.Origen || 'N/A',
                            "Fecha Registro": detalle.FechaRegistro ? new Date(detalle.FechaRegistro) : null,
                            "Tipo de Mantenimiento": detalle.TipoMantenimiento || 'N/A',
                            "Técnico": detalle.Tecnico || 'N/A',
                            "Almacén Origen": detalle.AlmacenOrigen || 'N/A',
                            "Almacén Destino": detalle.AlmacenDestinoData?.Nombre || 'N/A',
                            "Evento": detalle.EstadoMantenimiento || 'N/A',
                            "Comentarios": detalle.Comentarios || 'N/A',
                        });
                    });
                } else {
                    combinedData.push({
                        "Número Solicitud": `SOL-MANT-${String(head.Correlativo).padStart(6, '0')}`,
                        "Supervisor": head.Usuario?.Nombres || 'N/A',
                        "Código Cliente": head.TipoNegocio?.CodigoExterno || 'N/A',
                        "Cliente": head.TipoNegocio?.Nombre || 'N/A',
                        "Código Sede": head.Almacene?.CodigoExterno || 'N/A',
                        "Almacén": head.Almacene?.Nombre || 'N/A',
                        "Maquina": head.Articulo?.ItemName || 'N/A',
                        "Placa": head.Placa || 'N/A',
                        "Fecha Solicitud": head.FechaCreacion ? new Date(head.FechaCreacion) : null,
                        "Fecha Cierre": fechaCierre, 
                        "Estado Solicitud": head.EstadoMantenimiento || 'N/A',
                        "Fecha Registro": null,
                        "Tipo de Mantenimiento": 'N/A',
                        "Técnico": 'N/A',
                        "Almacén Origen": 'N/A',
                        "Almacén Destino": 'N/A',
                        "Tipo Incidencia": head.TipoIncidencia || 'N/A',
                        "Costo": head.Costo || 'N/A',
                        "Origen": head.Origen || 'N/A',
                        "Evento": 'N/A',
                    });
                }
            });
        
            const wb = XLSX.utils.book_new();
            const ws = XLSX.utils.json_to_sheet(combinedData, { cellDates: true });
        
            XLSX.utils.book_append_sheet(wb, ws, 'Mantenimientos');
            XLSX.writeFile(wb, 'Mantenimientos.xlsx');
        };
        const exportToExcel = async () => {
            const allMantenimientos = await fetchAllMantenimientos(
                estadoMantenimiento, placa, cuenta, dateInit, dateFin, filtroReprogramado
            );
        
            if (allMantenimientos.length === 0) {
                console.warn("⚠ No hay datos para exportar.");
                return;
            }
        
            const combinedData = allMantenimientos.map((head) => {
                let fechaSolicitud = null;
                let fechaCierre = null;
            
                if (head.TipoMantenimiento === "Correctivo") {
                    fechaSolicitud = head.Auditoria?.FechaSolicitudCorrectivo ? new Date(head.Auditoria.FechaSolicitudCorrectivo) : null;
                    fechaCierre = head.Auditoria?.FechaFinCorrectivo ? new Date(head.Auditoria.FechaFinCorrectivo) : null;
                } else if (head.TipoMantenimiento === "Preventivo") {
                    fechaSolicitud = head.Auditoria?.FechaProgramadaPreventivo ? new Date(head.Auditoria.FechaProgramadaPreventivo) : null;
                    fechaCierre = head.Auditoria?.FechaFinPreventivo ? new Date(head.Auditoria.FechaFinPreventivo) : null;
                } else if (head.TipoMantenimiento === "Predictivo") {
                    fechaSolicitud = head.Auditoria?.FechaProgramadaPredictivo ? new Date(head.Auditoria.FechaProgramadaPredictivo) : null;
                    fechaCierre = head.Auditoria?.FechaFinPredictivo ? new Date(head.Auditoria.FechaFinPredictivo) : null;
                }
            
                return {
                    "Número Solicitud": `SOL-MANT-${String(head.Correlativo).padStart(6, '0')}`,
                    "Tipo Mant.": head.TipoMantenimiento || 'N/A',
                    "Supervisor": head.Usuario?.Nombres || 'N/A',
                    "Código Cliente": head.TipoNegocio?.CodigoExterno || 'N/A',
                    "Cliente": head.TipoNegocio?.Nombre || 'N/A',
                    "Código Sede": head.Almacene?.CodigoExterno || 'N/A',
                    "Almacén": head.Almacene?.Nombre || 'N/A',
                    "Maquina": head.Articulo?.ItemName || 'N/A',
                    "Placa": head.Placa || 'N/A',
                    "Fecha Solicitud": head.FechaCreacion ? new Date(head.FechaCreacion) : null,
                    "Fecha Cierre": fechaCierre,
                    "Estado Solicitud": head.EstadoMantenimiento || 'N/A',
                    "Comentarios": head.ComentarioFinalizado || ''
                };
            });
            
            const wb = XLSX.utils.book_new();
            const ws = XLSX.utils.json_to_sheet(combinedData, { cellDates: true });
        
            XLSX.utils.book_append_sheet(wb, ws, 'Mantenimientos');
            XLSX.writeFile(wb, 'Mantenimientos.xlsx');
        };
            
        return (
            <Container
            maxWidth={isMobile ? false : 'lg'}
            disableGutters={isMobile}
            fixed={!isMobile}
          >
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} sm={6}>
                <Typography variant="h1" sx={{ color: theme.palette.primary.main }}>
                  Mantenimientos
                </Typography>
              </Grid>
          
              <Grid item display="flex" justifyContent="flex-end" alignItems="center" flexGrow={1}>
                <Tooltip title="Resetear Filtros">
                  <span>
                    <Button
                      variant="outlined"
                      color="primary"
                      size="medium"
                      sx={{
                        marginRight: { xs: '10px', sm: '20px', md: '30px' },
                        minWidth: '40px',
                        padding: '6px 10px',
                        minHeight: '40px'
                      }}
                      onClick={() => {
                        setFiltroTipoMantenimiento('');
                        setFiltroEstadoMantenimiento('');
                        setCuenta('');
                        setPlaca('');
                        setDateInit(null);
                        setDateFin(null);
                        setFiltroReprogramado('');
                        setPage(1);
                        find('', '', '', '', null, null, '', 1, pageSize);
                      }}
                    >
                      <RestartAltIcon />
                    </Button>
                  </span>
                </Tooltip>
          
                <Button
                  variant="contained"
                  color="primary"
                  size="medium"
                  sx={{
                    marginRight: { xs: '10px', sm: '20px', md: '30px' },
                    minWidth: '100px',
                    textAlign: 'center',
                  }}
                  onClick={() => setOpenModal(true)}
                >
                  Nuevo
                </Button>
          
                {/* Botón Generar */}
                <Tooltip title="Generar mantenimientos programados">
                  <span>
                    <Button
                      variant="contained"
                      color="primary"
                      size="medium"
                      sx={{
                        marginRight: { xs: '10px', sm: '20px', md: '30px' },
                        minWidth: '100px',
                        textAlign: 'center',
                      }}
                      onClick={handleOpenConfirmModal}
                      disabled={loadingCreacionMantenimientos}
                    >
                      {loadingCreacionMantenimientos
                        ? 'Creando mantenimientos...'
                        : 'Generar'}
                    </Button>
                  </span>
                </Tooltip>
              </Grid>
        <Grid item xs={12}>
        <Grid container spacing={gridSpacing} alignItems="center">
            {[
                { title: 'No Iniciado', color: theme.palette.warning.main, stat: stats["NO_INICIADO"], filtro: 'No Iniciado' },
                { title: 'Programado', color: theme.palette.primary.main, stat: stats["PROGRAMADO"], filtro: 'Programado' },
                { title: 'En Proceso', color: '#4CAF50', stat: stats["EN_PROCESO"], filtro: 'En Proceso' },
                { title: 'Por Confirmar', color: theme.palette.info.main, stat: stats["PENDIENTE_POR_CONFIRMAR"], filtro: 'Pendiente por Confirmar' },
                { title: 'Finalizado', color: '#B0B0B0', stat: stats["FINALIZADO"], filtro: 'Finalizado' },
            ].map((item, index) => {
                const isActive = filtroEstadoMantenimiento === item.filtro;

                return (
                <Grid item xs={6} sm={6} md={4} lg={2.4} key={index}>
                    <CardWrapper
                    border={false}
                    content={false}
                    sx={{
                        cursor: 'pointer',
                        backgroundColor: isActive ? '#e3f2fd' : 'white',
                        border: isActive ? '2px solid #1976d2' : '1px solid #e0e0e0',
                        boxShadow: isActive ? '0 0 10px rgba(25, 118, 210, 0.3)' : 'none',
                        transition: 'all 0.2s ease-in-out'
                    }}
                    onClick={() => {
                        const nuevoFiltro = isActive ? '' : item.filtro;
                        setFiltroEstadoMantenimiento(nuevoFiltro);
                        setPage(1);
                        find(filtroTipoMantenimiento, nuevoFiltro, placa, cuenta, dateInit, dateFin, filtroReprogramado, 1, pageSize);
                    }}
                    >
                    <StatsCard
                        title={item.title}
                        color={item.color}
                        number={item.stat}
                    />
                    </CardWrapper>
                </Grid>
                );
            })}
            </Grid>
            </Grid>
                    <Grid item xs={12}>
                    <Grid container spacing={2} alignItems="center">
                    <Grid item xs={6} sm={6} md={2}>
                        <FormControl fullWidth>
                            <InputLabel id="filtro-tipo-mant-label">Filtrar por Tipo Mant.</InputLabel>
                            <Select
                            labelId="filtro-tipo-mant-label"
                            label="Filtrar por Tipo Mant."
                            value={filtroTipoMantenimiento}
                            onChange={(e) => setFiltroTipoMantenimiento(e.target.value)}
                          //  displayEmpty
                            >
                            <MenuItem value="">
                                <em>Seleccionar</em>
                            </MenuItem>
                            {['Correctivo', 'Preventivo', 'Predictivo'].map((estado) => (
                                <MenuItem key={estado} value={estado}>
                                {estado}
                                </MenuItem>
                            ))}
                            </Select>
                        </FormControl>
                        </Grid>
                        <Grid item xs={6} sm={6} md={2}>
                            <FormControl fullWidth>
                                <InputLabel>Cuenta/Área</InputLabel>
                                <Select
                                    value={cuenta}
                                    onChange={(e) => setCuenta(e.target.value)}
                                    label="Cuenta/Área"
                                >
                                    <MenuItem value="">--Seleccionar--</MenuItem>
                                    {cuentaOptionsFiltro.map((option) => (
                                        <MenuItem
                                            key={option.tipo === 'negocio' ? option.IdNegocio : option.IdProveedor}
                                            value={option.tipo === 'negocio' ? option.IdNegocio : option.IdProveedor}
                                        >
                                            {option.Nombre}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6} sm={6} md={2}>
                            <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
                                <DesktopDatePicker
                                    label="Fecha Inicio"
                                    inputFormat="dd/MM/yyyy"
                                    value={dateInit}
                                    onChange={(newValue) => setDateInit(newValue)}
                                    renderInput={(params) => <TextField {...params} fullWidth />}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={6} sm={6} md={2}>
                            <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
                                <DesktopDatePicker
                                    label="Fecha Fin"
                                    inputFormat="dd/MM/yyyy"
                                    value={dateFin}
                                    onChange={(newValue) => setDateFin(newValue)}
                                    renderInput={(params) => <TextField {...params} fullWidth />}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={4} sm={4} md={1}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                label="Placa"
                                value={placa}
                                onChange={(e) => setPlaca(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={4} sm={4} md={1}>
                            <FormControl fullWidth variant="outlined">
                                <InputLabel id="reprogramado-label">Reprogramado</InputLabel>
                                <Select
                                    labelId="reprogramado-label"
                                    //value={placa}
                                    onChange={(e) => setFiltroReprogramado(e.target.value)}
                                    label="Reprogramado"
                                >
                                    <MenuItem value="">
                                    </MenuItem>
                                    <MenuItem value="si">Si</MenuItem>
                                    <MenuItem value="no">No</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                       
                        <Grid item xs={4} sm={4} md={2} display="flex" justifyContent="flex-start" alignItems="center">
    <Tooltip title="Buscar">
        <IconButton onClick={handleSearch} color="primary">
            <SearchIcon />
        </IconButton>
    </Tooltip>

<Tooltip title="Exportar a Excel">
        <IconButton
            ref={anchorRef}
            size="large"
            onClick={handleToggle}
            sx={{
                backgroundColor: '#4CAF50',
                color: 'white',
                marginLeft: '10px',
                '&:hover': { backgroundColor: '#388E3C' },
            }}
        >
            <IconFileDownload />
        </IconButton>
    </Tooltip>
</Grid>
</Grid>
</Grid>
                    <Popper
    open={open}
    anchorEl={anchorRef.current}
    role={undefined}
    transition
    disablePortal
>
    {({ TransitionProps, placement }) => (
        <Grow
            {...TransitionProps}
            style={{
                transformOrigin:
                    placement === 'bottom' ? 'center top' : 'center bottom',
            }}
        >
            <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                    <MenuList autoFocusItem>
                        <MenuItem onClick={() => { exportToExcel(); handleClose(); }}>
                            Exportar Mantenimientos
                        </MenuItem>
                        <MenuItem onClick={() => { exportToExcelConDetalleMantenimiento(); handleClose(); }}>
                            Exportar Mantenimientos con Detalle
                        </MenuItem>
                    </MenuList>
                </ClickAwayListener>
            </Paper>
        </Grow>
    )}
</Popper>
 {/* <Grid item xs={1} textAlign="left">
        <Tooltip title="Leer Excel">
            <IconButton
                size="large"
                onClick={handleLeerExcel}
                sx={{
                    backgroundColor: '#2196F3', 
                    color: 'white',
                    '&:hover': {
                        backgroundColor: theme.palette.primary.dark,
                    }
                }}
            >
                <IconFileDownload />
            </IconButton>
        </Tooltip>
    </Grid>  */}

                    <Grid item xs={12}>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        {columns.map((column) => (
                                            <TableCell
                                                key={column.field}
                                                sx={{
                                                    textAlign: 'center',
                                                    whiteSpace: 'normal',
                                                    wordWrap: 'break-word',
                                                    maxWidth: '90px',
                                                }}
                                            >
                                                {column.headerName}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.map((row) => (
                                        <React.Fragment key={row.id}>
                                            <TableRow>
                                                {columns.map((column) => (
                                                    <TableCell key={column.field} align="center">
                                                        {column.renderCell
                                                            ? column.renderCell({ row })
                                                            : row[column.field]}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                            <ExpandedRow row={row} />
                                        </React.Fragment>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    <Grid item xs={12} display="flex" justifyContent="center" alignItems="center" sx={{ marginTop: 2 }}>
                        <Box display="flex" alignItems="center" sx={{ gap: 2 }}>
                            <Pagination
                                count={Math.ceil(totalCount / pageSize)}
                                page={page}
                                onChange={handlePageChange}
                                color="primary"
                                size="small"
                            />
                            <Typography variant="body1" sx={{ marginLeft: 2 }}>
                                Registros por página:
                            </Typography>
                            <Select value={pageSize} onChange={handlePageSizeChange} size="small">
                                <MenuItem value={10}>10</MenuItem>
                                <MenuItem value={20}>20</MenuItem>
                                <MenuItem value={30}>30</MenuItem>
                            </Select>
                            <Typography variant="body2" sx={{ marginLeft: 2 }}>
                                {startIndex} - {endIndex} de {totalCount} registros
                            </Typography>
                        </Box>
                    </Grid>
                    <PickArticle
                        open={openFind}
                        reload
                        handleClose={() => setOpenFind(false)}
                        handleSelectedArticle={handleSelectedArticle}
                        url={`${process.env.REACT_APP_API}business/api/articulo/getArticles`}
                        param={{ IdNegocio: cuenta, IdAlmacen: almacen }}
                        columns={[
                            { field: 'Articulo', headerName: 'Producto', width: 200 },
                            { field: 'CodArticulo', headerName: 'Cod. Producto', width: 200 },
                            { field: 'NombreNegocio', headerName: 'Nombre Negocio', width: 200 },
                            { field: 'Almacen', headerName: 'Almacén', width: 150 },
                            { field: 'Categoria', headerName: 'Categoría', width: 150 },
                            { field: 'Stock', headerName: 'Stock', type: 'number', width: 90 },
                        ]}
                    />
                    <PickSerie
                        open={openSerie}
                        handleClose={() => setOpenSerie(false)}
                        handleSelectedArticle={handleSelectedSerie}
                        url={`${process.env.REACT_APP_API}business/api/control_series/ListByArticle`}
                        param={{
                            IdNegocio: cuenta,
                            IdAlmacen: articulo.IdAlmacen,
                            IdArticulo: articulo.IdArticle,
                        }}
                        columns={columnsPickSerie}
                    />
                </Grid>
                <Modal
                                open={openErrorModal}
                                onClose={() => setOpenErrorModal(false)}
                                aria-labelledby="modal-error-title"
                                aria-describedby="modal-error-description"
                            >
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        width: 400,
                                        bgcolor: 'background.paper',
                                        boxShadow: 24,
                                        p: 4,
                                        borderRadius: 4,
                                        textAlign: 'center'
                                    }}
                                >
                                  <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: 50,
                                height: 50,
                                borderRadius: '50%',
                                bgcolor: 'red',
                                color: 'white',
                                fontSize: 40,
                                margin: '0 auto 16px auto',
                            }}
                        >
                            X
                        </Box>

                        <Typography id="modal-error-title" variant="h2" sx={{ color: 'primary.main', mb: 2 }}>
                            Error
                        </Typography>
                                    <Typography id="modal-error-description" sx={{ mb: 4 }}>
                                    La máquina se encuentra en proceso para el evento "Transferencia"                                    </Typography>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => setOpenErrorModal(false)}
                                        sx={{ width: '100px', mx: 'auto' }}
                                    >
                                        OK
                                    </Button>
                                </Box>
                            </Modal>
                        <Modal
                            open={openModalRechazar}
                            onClose={() => setOpenModalRechazar(false)}
                        >
                            <Box sx={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                width: 400,
                                bgcolor: 'background.paper',
                                boxShadow: 24,
                                p: 4,
                                borderRadius: 4, 
                                textAlign: 'center'
                            }}>
                                <Typography variant="h5" mb={2}>
                                    ¿Está seguro que desea Rechazar el mantenimiento?
                                </Typography>
                                <TextField
                                    fullWidth
                                    label="Comentarios"
                                    value={comentarioRechazo}
                                    onChange={(e) => setComentarioRechazo(e.target.value)}
                                    multiline
                                    rows={3}
                                    variant="outlined"
                                    margin="normal"
                                />
                                <Button
                                    variant="contained"
                                    color="error"
                                    onClick={() => {
                                        handleEstadoMantenimiento('Rechazar', selectedRow, comentarioRechazo);
                                        setOpenModalRechazar(false);
                                    }}
                                >
                                    Rechazar
                                </Button>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => setOpenModalRechazar(false)}
                                    sx={{ ml: 2 }}
                                >
                                    Cerrar
                                </Button>
                            </Box>
                        </Modal>
                        <Dialog
                            open={openConfirmModal}
                            onClose={handleCloseConfirmModal}
                            aria-labelledby="confirm-dialog-title"
                            aria-describedby="confirm-dialog-description"
                            maxWidth="sm"
                            fullWidth
                            PaperProps={{
                                sx: {
                                    borderRadius: 4,
                                    padding: 2, 
                                    backgroundColor: '#f7f7f7', 
                                    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
                                },
                            }}
                        >
                            <DialogTitle
                                id="confirm-dialog-title"
                                sx={{
                                    fontSize: '1.5rem',
                                    fontWeight: 'bold',
                                    textAlign: 'center',
                                    color: 'primary',
                                    marginBottom: 1,
                                }}
                            >
                                Confirmación
                            </DialogTitle>
                            <DialogContent
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    textAlign: 'center',
                                }}
                            >
                                <DialogContentText
                                    id="confirm-dialog-description"
                                    sx={{
                                        fontSize: '1rem',
                                        color: '#333',
                                        marginBottom: 2,
                                    }}
                                >
                                    ¿Estás seguro de que deseas generar las solicitudes de mantenimiento para este mes?
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions
                                sx={{
                                    justifyContent: 'center',
                                    paddingBottom: 2,
                                }}
                            >
                                <Button
                                    onClick={handleCloseConfirmModal}
                                    variant="outlined"
                                    color="secondary"
                                    sx={{
                                        textTransform: 'none',
                                        fontSize: '0.9rem',
                                        padding: '6px 16px',
                                    }}
                                >
                                    Cancelar
                                </Button>
                                <Button
                                    onClick={handleConfirm}
                                    variant="contained"
                                    color="primary"
                                    sx={{
                                        textTransform: 'none',
                                        fontSize: '0.9rem',
                                        padding: '6px 16px',
                                        marginLeft: 2,
                                    }}
                                >
                                    Confirmar
                                </Button>
                            </DialogActions>
                        </Dialog>

                        <Modal
                        open={openModalCancelar}
                        onClose={() => setOpenModalCancelar(false)}
                    >
                            <Box sx={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                width: 400,
                                bgcolor: 'background.paper',
                                boxShadow: 24,
                                p: 4,
                                borderRadius: 4, 
                                textAlign: 'center'
                            }}><Typography variant="h5" mb={2}>
                                ¿Está seguro que desea Cancelar el mantenimiento?
                            </Typography>
                            <TextField
                                fullWidth
                                label="Comentarios"
                                value={comentarioRechazo} 
                                onChange={(e) => setComentarioRechazo(e.target.value)}
                                multiline
                                rows={3}
                                variant="outlined"
                                margin="normal"
                            />
                            <Button
                                variant="contained"
                                color="error"
                                onClick={() => {
                                    handleEstadoMantenimiento('Cancelar', selectedRow, comentarioRechazo); 
                                    setOpenModalCancelar(false);
                                }}
                            >
                                Cancelar
                            </Button>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={() => setOpenModalCancelar(false)}
                                sx={{ ml: 2 }}
                            >
                                Cerrar
                            </Button>
                        </Box>
                    </Modal>
                    <Modal
                    open={openModalAprobar}
                    onClose={() => setOpenModalAprobar(false)}
                >
                            <Box sx={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                width: 400,
                                bgcolor: 'background.paper',
                                boxShadow: 24,
                                p: 4,
                                borderRadius: 4, 
                                textAlign: 'center'
                            }}>                        <Typography variant="h5" mb={2}>
                            ¿Está seguro que desea Aprobar el mantenimiento?
                        </Typography>
                        <TextField
                            fullWidth
                            label="Comentarios"
                            value={comentarioRechazo} // Usamos el mismo campo de comentarios
                            onChange={(e) => setComentarioRechazo(e.target.value)}
                            multiline
                            rows={3}
                            variant="outlined"
                            margin="normal"
                        />
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                handleEstadoMantenimiento('Aprobar', selectedRow, comentarioRechazo); 
                                setOpenModalAprobar(false);
                            }}
                        >
                            Aprobar
                        </Button>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => setOpenModalAprobar(false)}
                            sx={{ ml: 2 }}
                        >
                            Cerrar
                        </Button>
                    </Box>
                </Modal>
                <Modal
                open={openOrigenCostoModal}
                onClose={handleCloseOrigenCostoModal}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{ timeout: 500 }}
            >
                <Fade in={openOrigenCostoModal}>
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: 400,
                            bgcolor: 'background.paper',
                            boxShadow: 24,
                            p: 3,
                            borderRadius: 2,
                        }}
                    >
                        <IconButton
                            onClick={handleCloseOrigenCostoModal}
                            sx={{
                                position: 'absolute',
                                top: 8,
                                right: 8,
                                color: '#888',
                                '&:hover': { color: '#000' },
                            }}
                        >
                            <CloseIcon />
                        </IconButton>

                        <h3>Detalles Adicionales</h3>

                        <FormControl fullWidth size="small" margin="dense">
                            <InputLabel>Origen</InputLabel>
                            <Select
                                value={selectedOrigen}
                                label="Origen"
                                onChange={(e) => setSelectedOrigen(e.target.value)}
                            >
                                <MenuItem value="Mal uso">Mal uso</MenuItem>
                                <MenuItem value="Desgaste Natural">Desgaste Natural</MenuItem>
                                <MenuItem value="Otros">Otros</MenuItem>
                            </Select>
                        </FormControl>

                        <TextField
                            label="Costo"
                            variant="outlined"
                            fullWidth
                            margin="dense"
                            size="small" 
                            type="number"
                            value={costo}
                            onChange={(e) => setCosto(e.target.value)}
                        />

                        <Box mt={2} display="flex" justifyContent="flex-end" gap={2}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleAgregarOrigenCosto}
                            >
                                Agregar
                            </Button>
                            <Button
                                variant="contained"
                                color="error"
                                onClick={handleCloseOrigenCostoModal}
                            >
                                Cancelar
                            </Button>
                        </Box>
                    </Box>
                </Fade>
            </Modal>
            
            <Modal
    open={openModalReprogramar}
    onClose={handleCloseModalReprogramar}
    closeAfterTransition
    BackdropComponent={Backdrop}
    BackdropProps={{ timeout: 500 }}
>
    <Fade in={openModalReprogramar}>
        <Box
            sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 400,
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 3,
                borderRadius: 2,
            }}
        >
            <IconButton
                onClick={handleCloseModalReprogramar}
                sx={{
                    position: 'absolute',
                    top: 8,
                    right: 8,
                    color: '#888',
                    '&:hover': { color: '#000' },
                }}
            >
                <CloseIcon />
            </IconButton>

            <h3>Fecha de Reprogramación</h3>

            <hr style={{ margin: '16px 0', border: '0.5px solid #e0e0e0' }} />

            <Grid container spacing={2} alignItems="center">
                <Grid item xs={4}>
                    <label style={{ fontWeight: 'bold' }}>Fecha a Reprogramar:</label>
                </Grid>
                <Grid item xs={8}>
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
                        <DesktopDatePicker
                            label="Fecha Reprogramación"
                            inputFormat="dd/MM/yyyy"
                            value={fechaReprogramacion || new Date()}
                            onChange={(newValue) => {
                                setFechaReprogramacion(newValue);
                            }}
                            renderInput={(params) => (
                                <TextField 
                                    {...params} 
                                    fullWidth 
                                    variant="outlined" 
                                    margin="dense" 
                                />
                            )}
                        />
                    </LocalizationProvider>
                </Grid>
            </Grid>

            <Box mt={2} display="flex" justifyContent="flex-end" gap={2}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleAgregarReprogramacion}
                >
                    Reprogramar
                </Button>
                <Button
                    variant="contained"
                    color="error"
                    onClick={handleCloseModalReprogramar}
                >
                    Cancelar
                </Button>
            </Box>
        </Box>
    </Fade>
</Modal>


                <Modal
                    open={!!errorMessage}
                    onClose={() => setErrorMessage(null)}
                    aria-labelledby="modal-error-title"
                    aria-describedby="modal-error-description"
                >
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: 400,
                            bgcolor: 'background.paper',
                            boxShadow: 24,
                            p: 4,
                            borderRadius: 4, 
                            textAlign: 'center'
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: 50,
                                height: 50,
                                borderRadius: '50%',
                                bgcolor: 'red',
                                color: 'white',
                                fontSize: 40,
                                margin: '0 auto 16px auto',
                            }}
                        >
                            X
                        </Box>

                        <Typography id="modal-error-title" variant="h2" sx={{ color: 'primary.main', mb: 2 }}>
                            Error
                        </Typography>

                        <Typography id="modal-error-description" sx={{ mb: 4 }}>
                            {errorMessage}
                        </Typography>

                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => setErrorMessage(null)}
                            sx={{ width: '100px', mx: 'auto' }}
                        >
                            OK
                        </Button>
                    </Box>
                </Modal>


                        <Modal
                    open={openWarningModal}
                    onClose={handleCloseWarningModal}
                    aria-labelledby="modal-title"
                    aria-describedby="modal-description"
                >
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: 420,
                            bgcolor: 'background.paper',
                            boxShadow: 24,
                            p: 4,
                            textAlign: 'center',
                        }}
                    >
                        <Typography id="modal-title" variant="h3" component="h2">
                            Artículo sin stock
                        </Typography>
                        <Typography id="modal-description" variant="h4" sx={{ mt: 3 }}>
                            El artículo seleccionado no tiene stock disponible.
                        </Typography>
                        <Button
                            onClick={handleCloseWarningModal}
                            variant="contained"
                            color="primary"
                            sx={{ mt: 2 }}
                        >
                            Cerrar
                        </Button>
                    </Box>
                </Modal>
                        <Modal
                            open={openModalComentarios}
                            onClose={() => setOpenModalComentarios(false)}
                            aria-labelledby="modal-title"
                            aria-describedby="modal-description"
                        >
                            <Box
                                sx={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                                width: 800,
                                height: "52vh",
                                bgcolor: "background.paper",
                                p: 4,
                                overflowY: "auto",
                                paddingBottom: "16px",
                                boxShadow: 10, 
                                borderRadius: 4,
                                border: '1px solid #e0e0e0' 
                                }}
                            >
                                <IconButton
                                    onClick={() => setOpenModalComentarios(false)}
                                    sx={{ position: 'absolute', top: 8, right: 8 }}
                                >
                                    X
                                </IconButton>

                                <Typography id="modal-title" variant="h2"  component="h2" sx={{ mb: 2 , color: 'primary.main'}}>
                                    Detalles del Mantenimiento
                                </Typography>

                                <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ textAlign: 'center', fontWeight: 'bold' }}>Fecha Registro</TableCell>
                                    <TableCell sx={{ textAlign: 'center', fontWeight: 'bold' }}>Comentario</TableCell>
                                    <TableCell sx={{ textAlign: 'center', fontWeight: 'bold' }}>Evento Mantenimiento</TableCell>
                                    {detallesComentarios.some(
                                        (detalle) => detalle.FechaRegistro && /\d{2}\/\d{2}\/\d{4}/.test(detalle.FechaRegistro)
                                    ) && (
                                        <TableCell sx={{ textAlign: 'center', fontWeight: 'bold' }}>Fecha Programada</TableCell>
                                    )}
                                    <TableCell sx={{ textAlign: 'center', fontWeight: 'bold' }}>Origen</TableCell>
                                    <TableCell sx={{ textAlign: 'center', fontWeight: 'bold' }}>Costo</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {Array.isArray(detallesComentarios) && detallesComentarios.length > 0 ? (
                                    detallesComentarios.map((detalle) => {
                                        const esFechaRegistroValida =
                                            detalle.FechaRegistro && /\d{2}\/\d{2}\/\d{4}/.test(detalle.FechaRegistro);

                                        return (
                                            <TableRow key={detalle.IdDetalleMantenimiento}>
                                                <TableCell sx={{ textAlign: 'center' }}>
                                                    {esFechaRegistroValida
                                                        ? detalle.FechaRegistro
                                                        : detalle.FechaCreacion.split(' ')[0].split('-').reverse().join('/')}
                                                </TableCell>
                                                <TableCell sx={{ textAlign: 'center' }}>{detalle.Comentarios}</TableCell>
                                                <TableCell sx={{ textAlign: 'center' }}>
                                                    {detalle.EstadoMantenimiento}
                                                    {detalle.parametroNombre && (
                                                        <Box sx={{ color: 'green', mt: 1 }}>{detalle.parametroNombre}</Box>
                                                    )}
                                                </TableCell>
                                                {esFechaRegistroValida && (
                                                    <TableCell sx={{ textAlign: 'center' }}>
                                                    {detalle.FechaProgramada
                                                      ? new Date(detalle.FechaProgramada).toLocaleDateString('es-ES')
                                                      : ''}
                                                  </TableCell>
                                                )}
                                                <TableCell sx={{ textAlign: 'center' }}>{detalle.Origen}</TableCell>
                                                <TableCell sx={{ textAlign: 'center' }}>{detalle.Costo}</TableCell>
                                            </TableRow>
                                        );
                                    })
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={6} sx={{ textAlign: 'center' }}>
                                            No hay detalles disponibles
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>

                            <Grid container justifyContent="flex-end" sx={{ mt: 2 }}>
                                    <Grid item>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => setOpenModalComentarios(false)}
                                            size='small'
                                        >
                                            Cerrar
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Modal>
                        <Modal
                            open={openModal}
                            onClose={handleCloseModal} 
                            aria-labelledby="modal-title"
                            aria-describedby="modal-description"
                            >
                            <Box
                                sx={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                                width: 550,
                                bgcolor: "background.paper",
                                boxShadow: 24,
                                p: 4,
                                maxHeight: "100vh",
                                overflowY: "auto",
                                }}
                            >
                                <IconButton
                                onClick={handleCloseModal} 
                                sx={{ position: "absolute", top: 8, right: 8 }}
                                >
                                X
                                </IconButton>
                                <Typography
                                id="modal-title"
                                variant="h2"
                                component="h2"
                                marginBottom={2}
                                sx={{ color: 'primary.main' }}  
                                >
                                Registro de Mantenimiento
                                </Typography>

                                <Grid container spacing={1}> 
                                <Grid item xs={6}>
                                    <FormControl fullWidth variant="outlined" margin="dense">
                                    <InputLabel>Tipo de Mantenimiento</InputLabel>
                                    <Select
                                        value={tipoMantenimiento}
                                        onChange={(e) => setTipoMantenimiento(e.target.value)}
                                        label="Tipo de Mantenimiento"
                                    >
                                        <MenuItem value="Preventivo">Preventivo</MenuItem>
                                        <MenuItem value="Correctivo">Correctivo</MenuItem>
                                        <MenuItem value="Predictivo">Predictivo</MenuItem>
                                    </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={6}>
                                    <FormControl fullWidth variant="outlined" margin="dense"> 
                                    <InputLabel id="lblTipoAlmacen">Tipo de Almacén</InputLabel>
                                    <Select
                                        labelId="lblTipoAlmacen"
                                        label="Tipo de Almacén"
                                        value={tipoAlmacen}
                                        onChange={(event) => setTipoAlmacen(event.target.value)}
                                    >
                                        {tipoAlmacenOptions.map((option) => (
                                        <MenuItem key={option.id} value={option.valor_1 || option.nombre}>
                                            {option.nombre}
                                        </MenuItem>
                                        ))}
                                    </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={6}>
                                    <FormControl fullWidth variant="outlined" margin="dense">
                                    <InputLabel disabled={disabledCuenta}>Cuenta/Área</InputLabel>
                                    <Select
                                        value={cuenta}
                                        onChange={(e) => setCuenta(e.target.value)}
                                        label="Cuenta/Área"
                                        disabled={disabledCuenta}
                                    >
                                        <MenuItem value="">--Seleccionar--</MenuItem>
                                        {cuentaOptions.map((option) => (
                                        <MenuItem
                                            key={
                                            option.tipo === "negocio" ? option.IdNegocio : option.IdProveedor
                                            }
                                            value={
                                            option.tipo === "negocio" ? option.IdNegocio : option.IdProveedor
                                            }
                                        >
                                            {option.Nombre}
                                        </MenuItem>
                                        ))}
                                    </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={6}>
                                    <FormControl fullWidth variant="outlined" margin="dense"> 
                                    <InputLabel>Almacén</InputLabel>
                                    <Select
                                        value={almacen}
                                        onChange={(e) => setAlmacen(e.target.value)}
                                        label="Almacén"
                                    >
                                        {almacenOptions.map((option) => (
                                        <MenuItem key={option.id} value={option.id}>
                                            {option.nombre}
                                        </MenuItem>
                                        ))}
                                    </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={6}>
                                    <FormControl fullWidth variant="outlined" margin="dense"> 
                                    <InputLabel htmlFor="txtCodArticulo">Cod. de producto</InputLabel>
                                    <OutlinedInput
                                        id="txtCodArticulo"
                                        type="text"
                                        value={articulo.CodArticle}
                                        endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton onClick={handleOpenFind}>
                                            <Search />
                                            </IconButton>
                                        </InputAdornment>
                                        }
                                        label="Cod. de producto"
                                    />
                                    </FormControl>
                                </Grid>

                                <Grid item xs={6}>
                                    <FormControl fullWidth variant="outlined" margin="dense">
                                    <InputLabel htmlFor="txtSerie">Placa</InputLabel>
                                    <OutlinedInput
                                        id="txtSerie"
                                        type="text"
                                        value={`${serie.Placa}`}
                                        endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                            aria-label="buscar serie"
                                            onClick={() => setOpenSerie(true)}
                                            edge="end"
                                            >
                                            <SearchIcon />
                                            </IconButton>
                                        </InputAdornment>
                                        }
                                        label="Serie - Placa"
                                    />
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                    fullWidth
                                    label="Descripción"
                                    variant="outlined"
                                    margin="dense"
                                    value={articulo.Article}
                                    disabled
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                    fullWidth
                                    label="Comentarios"
                                    variant="outlined"
                                    value={comentarios}
                                    onChange={(e) => setComentarios(e.target.value)}
                                    multiline
                                    rows={2}
                                    margin="dense"
                                    />
                                </Grid>

                                <Grid item xs={12} marginTop={2}>
                                    <Accordion>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                            <Typography variant="h4" sx={{ color: theme.palette.primary.main }}>
                                                <AttachmentIcon style={{ marginBottom: -5, marginRight: 5 }} />
                                                Adjuntar Foto
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <StyledDropzone onDrop={handleDropMantenimiento} />
                                            <Typography variant="body2" sx={{ mt: 2 }}>
                                                Archivos seleccionados:
                                            </Typography>
                                            <ul>
                                                {archivo && archivo.length > 0 ? (
                                                    archivo.map((file, index) => (
                                                        <li key={index}>{file.name}</li>
                                                    ))
                                                ) : (
                                                    <Typography variant="body2" sx={{ mt: 1 }}>
                                                        No se han seleccionado archivos.
                                                    </Typography>
                                                )}
                                            </ul>
                                        </AccordionDetails>
                                    </Accordion>

                                    <Grid
                                        container
                                        justifyContent="center"
                                        gap={2}
                                        marginTop={2}
                                    >

                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="medium"
                                            onClick={handleGuardar}
                                        >
                                            Guardar
                                        </Button>
                                    </Grid>
                                </Grid>

                                </Grid>
                            </Box>
                            </Modal>


                            <Modal
                            open={openRegistrarModal}
                            onClose={handleCloseModalRegistrar}
                            aria-labelledby="modal-inspeccion-title"
                            aria-describedby="modal-inspeccion-description"
                            >
                            <Box
                                sx={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                                width: 500,
                                bgcolor: "background.paper",
                                boxShadow: 24,
                                p: 4,
                                maxHeight: "90vh",
                                overflowY: "auto",
                                boxShadow: 10, 
                                borderRadius: 4,
                                border: '1px solid #e0e0e0' 
                                }}
                            >
                                <IconButton
                                onClick={handleCloseModalRegistrar}
                                sx={{ position: "absolute", top: 8, right: 8 }}
                                >
                                X
                                </IconButton>
                                <Typography
                                id="modal-inspeccion-title"
                                variant="h2"
                                component="h2"
                                sx={{ color: 'primary.main' }} 
                                marginBottom={2}
                                >
                                Registrar Evento
                                </Typography>

                                <Grid container spacing={1}>
                                <Grid item xs={6}>
                                    <TextField
                                    fullWidth
                                    label="Número de Solicitud"
                                    variant="outlined"
                                    margin="dense"
                                    value={selectedRowData?.correlativo || ''}
                                    disabled
                                    />
                                </Grid>

                                <Grid item xs={6}>
                                    <TextField
                                    fullWidth
                                    label="Supervisor"
                                    variant="outlined"
                                    margin="dense"
                                    value={selectedRowData?.usuarioCreacion || ''}
                                    disabled
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl fullWidth variant="outlined" margin="dense">
                                    <InputLabel>Seleccionar Técnico</InputLabel>
                                    <Select
                                        value={selectedTechnician}
                                        onChange={handleTechnicianChange}
                                        label="Seleccionar Técnico"
                                    >
                                        <MenuItem value="Juan Vicente">Juan Vicente</MenuItem>
                                        <MenuItem value="Danny Segovia">Danny Segovia</MenuItem>
                                        <MenuItem value="Pedro Sevilla">Pedro Sevilla</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                    fullWidth
                                    label="Tipo de Incidencia"
                                    variant="outlined"
                                    margin="dense"
                                    value={selectedRowData?.TipoIncidencia || ''}
                                    disabled
                                    />
                                </Grid>
                                <Grid item xs={6} sx={{ display: 'none' }}>
                                <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
                                    <DesktopDatePicker
                                        label="Fecha de Registro"
                                        inputFormat="dd/MM/yyyy"
                                        value={inspectionDate}
                                        onChange={setInspectionDate}
                                        disabled={true}
                                        renderInput={(params) => (
                                        <TextField {...params} fullWidth variant="outlined" margin="dense" />
                                        )}
                                    />
                                    </LocalizationProvider>
                                </Grid>

                                
                                {errorMessage && (
                                    <Modal
                                        open={!!errorMessage}
                                        onClose={() => setErrorMessage(null)}
                                        aria-labelledby="modal-error-title"
                                        aria-describedby="modal-error-description"
                                    >
                                        <Box
                                        sx={{
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            transform: 'translate(-50%, -50%)',
                                            width: 400,
                                            bgcolor: 'background.paper',
                                            boxShadow: 24,
                                            p: 4,
                                            borderRadius: 4, 
                                            textAlign: 'center'
                                        }}
                                        >
                                        <Box
                                            sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            width: 50,
                                            height: 50,
                                            borderRadius: '50%',
                                            bgcolor: 'red',
                                            color: 'white',
                                            fontSize: 40,
                                            margin: '0 auto 16px auto', 
                                            }}
                                        >
                                            X
                                        </Box>

                                        <Typography id="modal-error-title" variant="h2" sx={{ color: 'primary.main', mb: 2 }}>
                                            Error
                                        </Typography>

                                        <Typography id="modal-error-description" sx={{ mb: 4 }}>
                                            {errorMessage}
                                        </Typography>

                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => setErrorMessage(null)}
                                            sx={{ width: '100px', mx: 'auto' }}
                                        >
                                            OK
                                        </Button>
                                        </Box>
                                    </Modal>
                                    )}


                                <Grid item xs={6}>
                                
                                    <FormControl fullWidth variant="outlined" margin="dense">
                                    <InputLabel>Evento Mantenimiento</InputLabel>
                                    <Select
                                        value={estadoMantenimiento}
                                        onChange={(e) => setEstadoMantenimiento(e.target.value)}
                                        label="Estado Mantenimiento"
                                    >
                                        <MenuItem value="Programar Inspección">Programar Inspección</MenuItem>
                                        <MenuItem value="Inspección Técnica">Inspección Técnica</MenuItem>
                                        <MenuItem value="Transferencia">Transferencia</MenuItem>
                                        <MenuItem value="En Mantenimiento">En Mantenimiento</MenuItem>
                                        <MenuItem value="Mantenimiento Proveedor">Mantenimiento Proveedor</MenuItem>
                                        <MenuItem value="Pendiente Por Confirmar">Pendiente Por Confirmar</MenuItem>
                                    </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
                                <DesktopDatePicker
                            label="Fecha Programada"
                            inputFormat="dd/MM/yyyy"
                            value={fechaProgramada?.fechaProgramada || null}
                            onChange={(newValue) => {
                                setFechaProgramada((prev) => ({
                                    ...prev,
                                    fechaProgramada: newValue,
                                }));
                            }}
                            renderInput={(params) => (
                                <TextField 
                                    {...params} 
                                    fullWidth 
                                    variant="outlined" 
                                    margin="dense" 
                                />
                            )}
                        />

                                </LocalizationProvider>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                    fullWidth
                                    label="Comentarios"
                                    variant="outlined"
                                    value={comentariosRegistro}
                                    onChange={(e) => setComentariosRegistro(e.target.value)}
                                    multiline
                                    rows={2}
                                    margin="dense"
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                <Accordion>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography variant="h4" sx={{ color: theme.palette.primary.main }}>
                                            <AttachmentIcon style={{ marginBottom: -5, marginRight: 5 }} />
                                            Adjuntar Foto
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <StyledDropzone onDrop={handleDrop} />
                                        <Typography variant="body2" sx={{ mt: 2 }}>
                                            Archivos seleccionados:
                                        </Typography>
                                        <ul>
                                        {(attachedFile || []).map((file, index) => (
                                                <li key={index}>{file.name}</li>
                                            ))}
                                        </ul>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                                <Grid item xs={12} sx={{ textAlign: 'right', mt:2 }}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="medium"
                                        onClick={handleSaveRegistrar}
                                    >
                                        Guardar
                                    </Button>
                                </Grid>

                                </Grid>
                            </Box>
                            </Modal>
            </Container>
        );
    }        
    export default MantenimientoMaquinarias;
